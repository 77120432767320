import axios from "axios";
import { Endpoint } from "@data-client/endpoint";
import { baseUrl } from "store/backend-config";
import { GroupEntity } from "store/dataclient/group-entity";

export const groupBaseUrl = `${baseUrl}/groups`;

export const GroupResource = {
    get: new Endpoint(
        ({ id }) => axios.get(`${groupBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'getGroup',
            schema: GroupEntity,
        },
    ),
    list: new Endpoint(
        () => axios.get(`${groupBaseUrl}`).then(response => response.data),
        {
            name: 'listGroups',
            schema: [GroupEntity],
            pollFrequency: 30 * 1000,
        },
    ),
    create: new Endpoint(
        ({ group }) => axios.post(`${groupBaseUrl}`, group).then(response => response.data),
        {
            name: 'createGroup',
            schema: GroupEntity,
            sideEffect: true,
            update: (newGroup) => ({
                [GroupResource.list.key()]: (groups) => [newGroup, ...groups],
            }),
        },
    ),
}
