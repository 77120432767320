import { Endpoint } from '@data-client/endpoint';
import { NetworkError } from '@data-client/rest';
import axios from 'axios';
import { baseUrl } from 'store/backend-config';
import { ApiMessageEntity } from 'store/dataclient/api-message-entity';
import { UserEntity, UserResource } from 'store/dataclient/user-entity';

import { dataclientController as restCtrl } from 'store/dataclient-store';

const baseUrlAuth = `${baseUrl}/auth`;

const login = ({ user }) =>
    axios.post(`${baseUrlAuth}/login`, user)
        .then(
            response => {
                processLoginResponse(response);
                return response.data;
            },
            error => Promise.reject(new NetworkError(error.response))
        );

function processLoginResponse(response) {
    let userData = response.data;
    if (userData) {
        if (userData?.accessToken) {
            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('email', userData.email);
            axios.defaults.headers.common['Authorization'] = `Bearer ${userData.accessToken}`;
            userData.currentUser = true;

            void restCtrl.setResponse(AuthResource.getCurrentUser, userData);
        }
    }
}

export const AuthResource = {
    login: new Endpoint(login, {
        name: 'authLogin',
        schema: UserEntity
    }),
    logout: new Endpoint(
        () => new Promise((resolve, _reject) => {
            // void restCtrl.resetEntireStore();
            localStorage.removeItem('user');
            axios.defaults.headers.common['Authorization'] = null;
            void restCtrl.invalidate(AuthResource.getCurrentUser);
            resolve(true);
        }),
        {
            name: 'authLogout',
        }
    ),
    getCurrentUser: new Endpoint(
        () => {
            const localUser = JSON.parse(localStorage.getItem('user'));
            if (localUser?.id) {
                void restCtrl.fetch(UserResource.get, { id: localUser.id })
                    .then(response => {
                        response.accessToken = localUser.accessToken;
                        void restCtrl.setResponse(AuthResource.getCurrentUser, response);
                    });
            }
            return new Promise((resolve, _reject) => {
                resolve(localUser);
            });
        },
        {
            name: 'authCurrentUser',
        }
    ),
    patch: new Endpoint(
        ({ user }) => {
            return axios.patch(`${baseUrlAuth}/profile`, user).then(response => {
                void restCtrl.setResponse(AuthResource.getCurrentUser, response.data);
                return response.data
            });
        },
        {
            name: 'authPatchProfile',
            schema: UserEntity,
            sideEffect: true,
        }
    ),
    register: new Endpoint(
        ({ user }) => {
            return axios.post(`${baseUrlAuth}/register`, user).then(response => response.data);
        },
        {
            name: 'authRegisterUser',
            schema: ApiMessageEntity,
        }
    ),
    checkLoginAvailability: new Endpoint(
        ({ loginId }) => axios.get(`${baseUrlAuth}/check-login-available`, { params: { login: loginId } })
            .then(response => response.data),
        {
            name: 'authCheckLoginAvailability',
        }
    ),
    googleLogin: new Endpoint(
        ({ tokenResponse }) => axios.post(`${baseUrlAuth}/login/google`, tokenResponse)
            .then(response => {
                processLoginResponse(response);
                return response.data
            }),
        {
            name: 'authGoogleLogin',
            schema: UserEntity,
        }
    )
};

