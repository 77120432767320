import { useMobileDetector } from 'hooks/theme-hooks';
import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';

import { Box, Paper } from '@mui/material';
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GroupIcon from '@mui/icons-material/Group';
import { useTranslation } from 'react-i18next';
import { bottomNavigationHeight } from 'config';


// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const mobileDevice = useMobileDetector();
    const [currentMobileTab, setCurrentMobileTab] = useState('');

    const handleChange = (event, newValue) => {
        setCurrentMobileTab(newValue);
    };

    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

    return (
        <Box component='nav' sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}>
            {!mobileDevice ? (
                <MiniDrawerStyled variant='permanent' open={open}>
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawerStyled>
            ) : (
                 <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={1}>
                     <BottomNavigation sx={{ width: '100%', height: bottomNavigationHeight, borderTop: `1px solid ${theme.palette.divider}`, }} value={currentMobileTab} onChange={handleChange}>
                         <BottomNavigationAction
                             component={Link}
                             to='/dashboard/default'
                             label={t(`dashboard`)}
                             value='dashboard'
                             icon={<DashboardOutlinedIcon sx={{height: 20}}/>}

                         />
                         <BottomNavigationAction
                             component={Link}
                             label={t(`projects`)}
                             value='projects'
                             to='/projects'
                             icon={<ChecklistIcon  sx={{height: 20}}/>}
                         />
                         <BottomNavigationAction
                             component={Link}
                             label={t(`timers`)}
                             to='/timers/grid'
                             value='timers'
                             icon={<TimelapseIcon sx={{height: 20}}/>}
                         />
                         <BottomNavigationAction
                             component={Link}
                             label={t(`friends`)}
                             to='/profile/friends'
                             value='friends'
                             icon={<GroupIcon sx={{height: 20}}/>} />
                     </BottomNavigation>
                 </Paper>
             )}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
};

export default memo(MainDrawer);
