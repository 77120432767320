export const EntityTypes = {
    API_MESSAGE: "API_MESSAGE",
    ENTITY: "ENTITY",
    FILE_INFO: "FILE_INFO",
    GROUP: "GROUP",
    PROJECT: "PROJECT",
    SHARE_LINK: "SHARE_LINK",
    TASK: "TASK",
    TIMER: "TIMER",
    TIMER_EVENT: "TIMER_EVENT",
    TIMER_SESSION: "TIMER_SESSION",
    TIMER_WRAPPER: "TIMER_WRAPPER",
    USER: "USER",
    USER_EFFORT: "USER_EFFORT",
    USER_RELATION_EVENT: "USER_RELATION_EVENT",
}
