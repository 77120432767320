import { IconButton } from '@mui/material';
import PopperMenuButton from 'components/common/PopperMenuButton';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

/**
 * MUI IconButton, opens a popper (on PC) or drawer (on mobile) with specified contens when clicked
 * @example
 * <PopperMenuIconButton
 *      popperContents={<div>PREVED</div>}
 *      popperProps={{placement: 'top-start'}}
 * >
 *     <CheckOutlinedIcon />
 * </PopperMenuIconButton>
 * @param {JSX.Element} children MUI icon component
 * @param {JSX.Element} popperContents react node to display inside the popper,
 * may have onClose prop to signal the popper to close itself
 * @param {object} [popperProps] properties for the Popper component
 * @param {object} [drawerProps] properties for the SwipeableDrawer component
 * @param {function()} [onClick] callback to append to child's onClick callback
 * @param {function()} [onClose] callback to call when popper is being closed
 * @param [props] passed to IconButton
 * @param ref forwarded to IconButton
 * @returns {JSX.Element}
 */
const PopperMenuIconButton = forwardRef(({ children, popperContents, popperProps, drawerProps, onClick, onClose, ...props }, ref) => {
    return (
        <PopperMenuButton
            popperContents={popperContents}
            popperProps={popperProps}
            onClick={onClick}
            onClose={onClose}
            ref={ref}
        >
            <IconButton
                {...props}
            >
                {children}
            </IconButton>
        </PopperMenuButton>
    )
})

PopperMenuIconButton.propTypes = {
    children: PropTypes.element.isRequired,
    popperContents: PropTypes.element,
    popperProps: PropTypes.object,
    drawerProps: PropTypes.object,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
}

export default PopperMenuIconButton;