import { useController } from '@data-client/react';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AuthResource } from 'store/dataclient/auth-resource';
import { login, logout, selectCurrentUser } from 'store/reducers/slice-auth';

export const useCurrentUser = () => {
    return useSelector(selectCurrentUser, shallowEqual);
}

export const useLogout = () => {
    const restCtrl = useController();
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(logout());
        void restCtrl.resetEntireStore();
    }, [restCtrl, dispatch]);
};

export const useLoginAvailabilityChecker = () => {
    const restCtrl = useController();
    return useCallback(
        loginId => restCtrl.fetch(AuthResource.checkLoginAvailability, { loginId: loginId })
        , [restCtrl]);
};

export const useRegisterCallback = () => {
    const restCtrl = useController();
    return useCallback(
        user => restCtrl.fetch(AuthResource.register, { user: user })
        , [restCtrl]);
};

export const useLoginCallback = () => {
    const restCtrl = useController();
    const dispatch = useDispatch();
    return useCallback(
        loginData =>
            restCtrl.fetch(AuthResource.login, { user: loginData })
                .then(userData => {
                    if (userData?.accessToken)
                        dispatch(login(Object.assign({}, userData)));
                })
        , [restCtrl, dispatch])
}

export const useGoogleAuthCallback = () => {
    const restCtrl = useController();
    const dispatch = useDispatch();
    return useCallback(
        tokenResponse =>
            restCtrl.fetch(AuthResource.googleLogin, { tokenResponse: tokenResponse })
                .then(userData => {
                    if (userData?.accessToken)
                        dispatch(login(Object.assign({}, userData)));
                })
        , [restCtrl, dispatch]);
}

export const useUpdateProfileCallback = () => {
    const restCtrl = useController();
    return useCallback(user => restCtrl.fetch(AuthResource.patch, { user: user }), [restCtrl]);
}
