import { AsyncBoundary } from "@data-client/react";
import { ShareableTargetList } from "components/shares/ShareableTargetList";
import PropTypes from "prop-types";
import { useState } from "react";

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ClickAwayListener, IconButton, Paper, Popper } from "@mui/material";

export const ShareButton = ({ parentId, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const popupOpened = Boolean(anchorEl);

    return (
        <>
            <IconButton
                variant='outlined'
                onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
                {...props}
            >
                <ShareOutlinedIcon />
            </IconButton>
            {popupOpened &&
                <Popper
                    sx={{ zIndex: 1200 }}
                    open={popupOpened}
                    anchorEl={anchorEl}
                    placement='bottom-end'
                    disablePortal
                >
                    <ClickAwayListener
                        onClickAway={() => setAnchorEl(null)}
                        // onClickAway={handlePopupClose}
                    >
                        <Paper>
                            <AsyncBoundary>
                                <ShareableTargetList parentId={parentId} />
                            </AsyncBoundary>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            }
        </>
    );
};

ShareButton.propTypes = {
    parentId: PropTypes.string,
}
