import axios from "axios";
import { Endpoint } from '@data-client/endpoint';
import { HanaEntityImpl } from "store/dataclient/hana-entity-impl";
import { HanaEntityResource } from "store/dataclient/hana-entity-resource";
import { TimerSessionResource } from "store/dataclient/timer-session-resource";
import { UserEffortResource } from "store/dataclient/user-effort-resource";

import { baseUrl } from "../backend-config";
import { dataclientController as restCtrl } from "store/dataclient-store";

export const timerBaseUrl = `${baseUrl}/timers`;

export const TimerResource = {
    run: new Endpoint(
        ({ id }) => axios.post(`${timerBaseUrl}/v2/${id}/run`).then(response => {
            void restCtrl.expireAll(TimerSessionResource.listRunning);
            void restCtrl.expireAll(TimerSessionResource.list);
            return response.data
        }),
        {
            name: 'runTimer',
            schema: HanaEntityImpl,
            sideEffect: true,
        }
    ),
    pause: new Endpoint(
        ({ id }) => axios.post(`${timerBaseUrl}/v2/${id}/pause`).then(response => {
            void restCtrl.expireAll(TimerSessionResource.listRunning);
            void restCtrl.expireAll(TimerSessionResource.list);
            void restCtrl.expireAll(UserEffortResource.list);
            void restCtrl.expireAll(HanaEntityResource.list);
            return response.data
        }), {
            name: 'pauseTimer',
            schema: HanaEntityImpl,
            sideEffect: true,
        }
    ),
}
