import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en.json';
import translationPT from './pt.json';
import translationRU from './ru.json';

const resources = {
    en: {
        translation: translationEN
    },
    pt: {
        translation: translationPT
    },
    ru: {
        translation: translationRU
    }
};

void i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
    });

export default i18n;
