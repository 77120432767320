import { useCurrentUser } from "hooks/auth-hooks";
import { memo } from 'react';
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from 'prop-types';

const ProtectedRoute = ({ roles, element }) => {
    // const { user, isLoggedIn } = useSelector(state => state.auth);
    const user = useCurrentUser();
    // const isLoggedIn = Boolean(user);

    const location = useLocation();

    if ( !user || !user.id) {
        return <Navigate to='/login' state={{ from: location }} replace />
    }

    if ( !roles
        || roles.find((role) => user.roles.includes(role))
    ) {
        return element;
    } else {
        return (<Navigate to='/login' state={{ from: location }} replace />);
    }
};

export default memo(ProtectedRoute);

ProtectedRoute.propTypes = {
    roles: PropTypes.array,
    element: PropTypes.object,
}

ProtectedRoute.whyDidYouRender = true;
