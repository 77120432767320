// import { diff } from "deep-object-diff";
import { dequal } from "dequal";
import { useMemo, useEffect, useRef } from "react";

function checkDeps(deps) {
    if ( !deps || !deps.length) {
        throw new Error(
            'useDeepCompare* should not be used with no dependencies. Use React.useEffect instead.',
        )
    }
    if (deps.every(isPrimitive)) {
        throw new Error(
            'useDeepCompare* should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
        )
    }
}

function isPrimitive(val) {
    return val == null || /^[sbn]/.test(typeof val)
}

export function useDeepCompareMemoize(value) {
    const ref = useRef(value)
    const signalRef = useRef(0)

    if ( !dequal(value, ref.current)) {
        // console.log('deep compare memoize changed');
        // console.log(value);
        // console.log(diff(ref.current, value));
        ref.current = value
        signalRef.current += 1
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => ref.current, [signalRef.current])
}

export function useDeepCompareEffect(callback, dependencies) {
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useDeepCompareMemo(callback, dependencies) {
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareEffectNoCheck(callback, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(callback, useDeepCompareMemoize(dependencies))
}

