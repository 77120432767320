// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          '&.MuiIconButton-colorSecondary': {backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[200],},
          '&.MuiIconButton-colorSecondary:hover': {backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[300],},
          '&:hover': {backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[100],},
        },
        sizeLarge: {
          width: theme.spacing(5.5),
          height: theme.spacing(5.5),
          fontSize: '1.25rem'
        },
        sizeMedium: {
          width: theme.spacing(4.5),
          height: theme.spacing(4.5),
          fontSize: '1rem'
        },
        sizeSmall: {
          width: theme.spacing(3.75),
          height: theme.spacing(3.75),
          fontSize: '0.75rem'
        },
        imageBackGround: {
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
          },
        }
      },
      variants: [
        {
          props: { variant: 'box-card' },
          style: {
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
            },
          },
        },
      ],
    }
  };
}
