import { AsyncBoundary } from '@data-client/react';
import { List } from '@mui/material';
import FriendshipRequestListItem from 'components/users/FriendshipRequestListItem';
import { useFriendshipRequests } from 'hooks/user-friendship-hooks';
import { UserRelationEventStates } from 'types/user-relation-event';
import { comparatorCreatedAtDesc } from 'utils/comparators';

/**
 * MUI List, displays friendship request items
 * @param [props] passed to List
 * @returns {JSX.Element}
 * @constructor
 */
const FriendshipRequestList = ({ ...props }) => {
    const requests = useFriendshipRequests()
        .filter(request => request.eventState === UserRelationEventStates.FRIEND_REQUEST_PENDING);
    requests.sort(comparatorCreatedAtDesc);

    return (
        <List {...props}>
            {requests.map((request) => (
                <AsyncBoundary key={request.id}>
                    <FriendshipRequestListItem request={request} />
                </AsyncBoundary>
            ))}
        </List>
    )
}

export default FriendshipRequestList;