import {Typography} from "@mui/material";
import {useGroup} from "hooks/share-hooks";
import {useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const GroupPage = () => {
    const { t } = useTranslation();
    const { groupId } = useParams();
    const group = useGroup(groupId);

    return (
        <>
            <Typography variant='h3'>
            {t('group')} {group.title}
            </Typography>
        </>
    );
};

export default GroupPage;
