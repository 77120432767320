import dayjs from "dayjs";

export function computeTimerValue(timer, runningSessions) {
    if ( !timer)
        return 0;
    let value = timer.knownDuration;
    if (runningSessions?.length) {
        value = runningSessions
            // .map(session => session.endTime ? 0 : dayjs().diff(dayjs(session.startTime), 'second'))
            .map(session => session.duration ? session.duration : dayjs().diff(dayjs(session.startTime), 'second'))
            .reduce(
                (totalSeconds, sessionSeconds) => {
                    return totalSeconds + sessionSeconds
                },
                timer.knownDuration
            );
    }
    return value;
}

export function computeTimerSessionDuration(session) {
    if ( !session)
        return 0;
    if (session.endTime)
        return session.duration;

    return dayjs().diff(dayjs(session.startTime), 'second');
}
