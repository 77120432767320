import { Endpoint } from "@data-client/endpoint";
import axios from "axios";
import { baseUrl } from "store/backend-config";
import { ApiMessageEntity } from "store/dataclient/api-message-entity";
import { ShareLinkEntity } from "store/dataclient/share-link-entity";

export const shareLinkBaseUrl = `${baseUrl}/share-links`;

export const ShareLinkResource = {
    list: new Endpoint(
        () => axios.get(`${shareLinkBaseUrl}`).then(response => response.data),
        {
            name: 'listShareLinks',
            schema: [ShareLinkEntity],
        }
    ),
    get: new Endpoint(
        ({ id }) => axios.get(`${shareLinkBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'getShareLink',
            schema: ShareLinkEntity,
        }
    ),
    create: new Endpoint(
        ({ shareLink }) => axios.post(`${shareLinkBaseUrl}`, shareLink).then(response => response.data),
        {
            name: 'createShareLink',
            schema: ShareLinkEntity,
        }
    ),
    patch: new Endpoint(
        ({ id, shareLink }) => {
            if ( !id)
                id = shareLink.id;
            return axios.patch(`${shareLinkBaseUrl}/${id}`, shareLink).then(response => response.data)
        },
        {
            name: 'patchShareLink',
            schema: ShareLinkEntity,
        }
    ),
    accept: new Endpoint(
        ({ id }) => axios.get(`${shareLinkBaseUrl}/accept/${id}`).then(response => response.data),
        {
            name: 'acceptShareLink',
            schema: ApiMessageEntity,
        }
    )
}
