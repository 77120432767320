import PropTypes from "prop-types";
import { useState } from "react";

import { Box, Switch, Stack, Typography } from '@mui/material';

import { AsyncBoundary } from "@data-client/react";
import { TaskStateGroups } from "types/task";
import { useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import { comparatorCreatedAtDesc } from 'utils/comparators';
import TaskCard from "./TaskCard";
import TaskCreationMobile from './TaskCreationMobile';

export const TaskList = ({ tasks, _projectId }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [showOpenTasks, setShowOpenTasks] = useState(true);

    tasks.sort(comparatorCreatedAtDesc);

    const toggleShowOpenTasks = () => {
        setShowOpenTasks( !showOpenTasks);
    }


    return (
        <>
            <Stack direction='row' alignItems='center' justifyContent='flex-end' sx={{pr:1}}>
                <Typography sx={{color: theme.palette.text.secondary}}>{t('switch_task_completed')}</Typography>
                <Switch
                    checked={showOpenTasks}
                    onChange={toggleShowOpenTasks}
                />
                <Typography sx={{color: theme.palette.text.secondary}}>{t('switch_task_open')}</Typography>
            </Stack>
            <TaskCreationMobile />
            {showOpenTasks
             ? tasks
                 .filter(task => !TaskStateGroups.closed.includes(task.state))
                 .map(task =>
                     <AsyncBoundary key={task.id}>
                         <TaskCard task={task} />
                     </AsyncBoundary>,
                 )
             : tasks
                 .filter(task => TaskStateGroups.closed.includes(task.state))
                 .map(task =>
                     <AsyncBoundary key={task.id}>
                         <TaskCard task={task} />
                     </AsyncBoundary>,
                 )
            }

            <Box sx={{ height: '110px' }} />
        </>
    );
};

TaskList.propTypes = {
    tasks: PropTypes.array,
    _projectId: PropTypes.string,
}

export default TaskList;
