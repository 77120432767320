import GroupPage from "pages/extra-pages/GroupPage";
import {lazy} from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProtectedRoute from './ProtectedRoute';
import TimerPage from 'pages/extra-pages/TimerPage';
import ProjectPage from "pages/projects/ProjectPage";
import TaskPage from "pages/extra-pages/TaskPage";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const TimersGrid = Loadable(lazy(() => import('pages/extra-pages/TimersGrid')));
const ProjectsGrid = Loadable(lazy(() => import('pages/projects/ProjectsGrid')));
const GroupsTable = Loadable(lazy(() => import('pages/extra-pages/GroupsTable')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    // element: <MainLayout/>,
    element: <ProtectedRoute element={<MainLayout />} />,
    children: [
        { path: '/', element: <DashboardDefault /> },
        {
            path: 'dashboard',
            children: [
                { path: 'default', element: <DashboardDefault /> }
            ]
        },
        {
            path: 'projects',
            children: [
                { path: '', element: <ProjectsGrid /> },
                { path: ':projectId', element: <ProjectPage /> }
            ]
        },
        {
            path: 'timers',
            children: [
                { path: 'grid', element: <TimersGrid /> },
                { path: ':timerId', element: <TimerPage /> }
            ]
        },
        {
            path: 'groups',
            children: [
                { path: '', element: <GroupsTable /> },
                { path: ':groupId', element: <GroupPage /> },
            ]
        },
        {
            path: 'tasks',
            children: [
                { path: ':taskId', element: <TaskPage /> },
            ]
        },
    ]
};

export default MainRoutes;
