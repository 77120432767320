export default function Icon(theme) {
    return {
      MuiIcon: {
        styleOverrides: {
          root: {
            color: theme.palette.grey[500]
          },
        }
      }
    };
  }
  