import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * MUI Dialog with single text input field
 * @param {string} [title] dialog title
 * @param {string} [text] optional description
 * @param {string} [value] initial value for textfield
 * @param {boolean} open dialog state
 * @param {function()} onClose callback to call when dialog closes
 * @param {function(string)} onCommit callback called when user commits the changes
 * @param [props] passed to Dialog
 * @returns {JSX.Element}
 * @constructor
 */
const TextInputDialog = ({ title, text, value, open, onClose, onCommit, ...props }) => {
    const { t } = useTranslation();

    if ( !value) value = '';
    const [inputValue, setInputValue] = useState(value);

    const handleDialogClose = function() {
        setInputValue(value);
        if (onClose)
            onClose();
    }

    const handleDialogCommit = function() {
        onCommit(inputValue?.trim());
        handleDialogClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
            // MUI bug https://github.com/mui/material-ui/issues/33004
            // allows textfield autofocus in react strict mode
            disableRestoreFocus
            {...props}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {text && <DialogContentText>{text}</DialogContentText>}
                <TextField
                    autoFocus
                    fullWidth
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant='outlined'>{t('cancel')}</Button>
                <Button onClick={handleDialogCommit} variant='contained' disabled={ !inputValue.trim()}>{t('ok')}</Button>
            </DialogActions>
        </Dialog>
    )
}

TextInputDialog.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCommit: PropTypes.func.isRequired,
}

export default TextInputDialog;