import { useLogout } from "hooks/auth-hooks";
import PropTypes from "prop-types";
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// ==============================|| HEADER PROFILE - PROFILE MENU ||============================== //

const ProfileMenu = ({ onMenuItemSelect }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const handleLogout = useLogout();


    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleListItemClick = (_event, index) => {
        setSelectedIndex(index);
        onMenuItemSelect();

        switch (index) {
            case 0:
                navigate('/profile/settings', { replace: true });
                break;
            case 1:
                navigate('/share-links', { replace: true });
                break;
            default:
        }
    };

    return (
        <List component='nav'
              sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
            <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemIcon>
                    <SettingsOutlinedIcon sx={{ color: theme.palette.icon.main }} />
                </ListItemIcon>
                <ListItemText primary={t('settings')} />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 1)}>
                <ListItemIcon>
                    <ShareOutlinedIcon sx={{ color: theme.palette.icon.main }} />
                </ListItemIcon>
                <ListItemText primary='Share links' />
            </ListItemButton>

            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon sx={{ color: theme.palette.icon.main }} />
                </ListItemIcon>
                <ListItemText primary={t('logout')} />
            </ListItemButton>
        </List>
    );
};


ProfileMenu.propTypes = {
    onMenuItemSelect: PropTypes.func,
};

export default ProfileMenu;
