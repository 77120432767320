import TimerControlButton from "components/timers/TimerControlButton";
import { useEntityTimer } from "hooks/timer-hooks";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from 'react-router-dom';

import { Typography, Box, Paper } from "@mui/material";
import { formatDuration } from "utils/duration-formatter";

import OutlinedBox from "components/timers/outlined-box/OutlinedBox";
import EntityMenuButton from "components/common/EntityMenuButton";
import { useTheme } from '@mui/material/styles';

// const statusColors = {
//     [TaskStateType.NOT_STARTED]: 'lightgray',
//     [TaskStateType.IN_PROGRESS]: 'lightblue',
//     [TaskStateType.COMPLETED]: 'lightgreen',
//     [TaskStateType.CANCELLED]: 'lightcoral',
// };

const TaskCard = ({ task }) => {
    const theme = useTheme();
    const { timerValue, timerIsRunning } = useEntityTimer(task.id);

    return (
        <>
            <Paper elevation={0} sx={{ mt: 1, mb: 1.5 }}>
                <OutlinedBox
                    label={formatDuration(timerValue)}
                    color={timerIsRunning ? theme.palette.text.info : theme.palette.divider}
                >
                    <Box display='flex' justifyContent='space-between'
                         sx={{ alignItems: 'center', p: '8px', width: '100%', borderRadius: '6px' }}>
                        <Box display='flex' alignItems='center' sx={{ flexGrow: 1 }}>
                            <TimerControlButton
                                parentId={task.id}
                            />
                            <RouterLink to={`/tasks/${task.id}`}
                                        style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                                <Box width='100%'>
                                    <Typography
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                        }}>{task.title}
                                    </Typography>
                                </Box>
                            </RouterLink>
                        </Box>
                        <EntityMenuButton entityId={task.id} entityType='task' />
                    </Box>
                </OutlinedBox>
            </Paper>
        </>
    )
};

TaskCard.propTypes = {
    task: PropTypes.object,
}

export default TaskCard;
