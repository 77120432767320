import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectDataclient = (state) => state.dataclient;

export const selectEndpointMeta = (endpoint, ...args) => createDraftSafeSelector(selectDataclient,
    state => {
        const key = args[0] !== null ? endpoint.key(...args) : "";
        return state.meta[key];
    });

export const selectEndpointResult = (endpoint, ...args) => createDraftSafeSelector(selectDataclient,
    state => {
        const key = args[0] !== null ? endpoint.key(...args) : "";
        return state.results[key];
    });