import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

import { EntityTypes } from "types/enums/entity-types";
import { TaskStateType } from "types/task";

import { Paper, IconButton, TextField, SwipeableDrawer, InputAdornment } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { useEntityCreationCallback } from "hooks/entity-hooks";


const TaskCreationMobile = ({ parentId, open, setOpen }) => {
    const { _t } = useTranslation();
    const [title, setTitle] = useState('');
    const [entityId, setEntityId] = useState(uuidv4());

    const entityCreationCallback = useEntityCreationCallback();

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen(open);
    };

    const handleClose = useCallback(() => {
        setTitle('');
        setEntityId(uuidv4());
        setOpen(false);
    }, [setOpen]);

    const handleCreate = useCallback(() => {
        if ( !title?.trim())
            return;

        const entity = {
            title: title.trim(),
            id: entityId,
            state: TaskStateType.NOT_STARTED,

        };

        entity.entityType = EntityTypes.TASK;
        entity.parentId = parentId;

        entityCreationCallback(entity)
            .then(() => handleClose());

    }, [title, parentId, entityCreationCallback, handleClose, entityId]);

    return (
        <>
            <SwipeableDrawer
                anchor='bottom'
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                ModalProps={{
                    // MUI bug https://github.com/mui/material-ui/issues/33004
                    // allows textfield autofocus in react strict mode
                    disableRestoreFocus: true,
                }}
                PaperProps={{
                    sx: {
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                    },
                }}
            >
                <Paper sx={{ width: '100%', pb: '65px', pt: '10px' }}>
                    <TextField
                        label='Create Task'
                        multiline
                        autoFocus
                        rows={4}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment:
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='create task'
                                        edge='end'
                                        onClick={handleCreate}
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                </Paper>
            </SwipeableDrawer>
        </>
    )
};

TaskCreationMobile.propTypes = {
    parentId: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default TaskCreationMobile;
