import PropTypes from 'prop-types';
import { Fab, Zoom } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CreationDialog } from './CreationDialog';
import { useMobileDetector } from 'hooks/theme-hooks';
import { useState } from 'react';
import TaskCreationMobile from 'components/tasks/TaskCreationMobile';

export const CreationButton = ({ entityId, entityType }) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const mobileDevice = useMobileDetector();
    const fabRight = mobileDevice ? 15 : 72;

    return (
        <>
            <Zoom in style={{ transitionDelay: '100ms' }}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => setDialogOpened(true)}
                    sx={{
                        position: 'fixed',
                        bottom: mobileDevice ? 115 : 72,
                        right: fabRight,
                    }}
                >
                    <AddIcon />
                </Fab>
            </Zoom>
            {!mobileDevice && dialogOpened && (
                <CreationDialog
                    parentId={entityId}
                    entityType={entityType}
                    autoFocus
                    open={dialogOpened}
                    onClose={() => setDialogOpened(false)}
                />
            )}
            {mobileDevice && dialogOpened && (
                entityType === 'task'
                    ? <TaskCreationMobile
                        parentId={entityId}
                        open={dialogOpened}
                        setOpen={setDialogOpened} // This prop needs to be handled in TaskCreationMobile
                    />
                    : <CreationDialog
                        parentId={entityId}
                        entityType={entityType}
                        autoFocus
                        open={dialogOpened}
                        onClose={() => setDialogOpened(false)}
                    />
            )}
        </>
    );
};

CreationButton.propTypes = {
    entityId: PropTypes.string,
    entityType: PropTypes.string.isRequired,
};

export default CreationButton;
