import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));

if (user?.accessToken)
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;

const email = localStorage.getItem("email") || "";

const initialState = user
                     ? { isLoggedIn: true, email: email, user }
                     : { isLoggedIn: false, email: email, user: null };

const sliceAuth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state, _action) => {
            localStorage.removeItem("user");
            state.isLoggedIn = false;
            state.user = null;
            axios.defaults.headers.common['Authorization'] = null;
        },
        login: {
            reducer: (state, action) => {
                const user = action.payload;
                if (user?.accessToken) {
                    state.isLoggedIn = true;
                    state.user = user;
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("email", user.email);

                    axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;
                }
            },
            prepare: (value) => ({ payload: value }),
        },
    },
});

export const { login, logout } = sliceAuth.actions;
export default sliceAuth.reducer;

const selectSelf = (state) => state;
export const selectUserId = createDraftSafeSelector(selectSelf, state => state.auth.user?.id);

export const selectCurrentUser = createDraftSafeSelector(selectSelf, state => state.auth.user);
