import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import ProtectedRoute from "routes/ProtectedRoute";

const ShareLinkAcceptPage = Loadable(lazy(() => import('pages/extra-pages/ShareLinkAcceptPage')));
const ShareLinksPage = Loadable(lazy(() => import('pages/extra-pages/ShareLinksPage')));

const ShareLinkRoutes = {
    path: '/share-links',
    element: <ProtectedRoute element={<MainLayout />} />,
    children: [
        {
            path: '/share-links',
            element: <ShareLinksPage />
        },
        {
            path: 'accept/:linkId',
            element: <ShareLinkAcceptPage />
        }
    ]
}

export default ShareLinkRoutes;
