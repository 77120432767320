import FriendshipRequestMenuButton from 'components/users/FriendshipRequestMenuButton';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { useOwnership } from 'hooks/share-hooks';
import { useUser } from 'hooks/user-hooks';
import { UserRelationEventStates } from 'types/user-relation-event';

import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import UserAvatar from 'components/users/UserAvatar';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

/**
 * MUI ListItem, displays friendship request
 * @param {object} request friendship request object
 * @param [props] passed to ListItem
 * @returns {JSX.Element}
 * @constructor
 */
const FriendshipRequestListItem = ({ request, ...props }) => {
    const isOwnRequest = useOwnership(request.ownerId);
    const displayUser = useUser(isOwnRequest ? request.targetId : request.ownerId);

    let stateDecoration = null;
    switch (request.eventState) {
        case UserRelationEventStates.FRIEND_REQUEST_ACCEPTED:
            stateDecoration = <CheckOutlinedIcon />;
            break;
        case UserRelationEventStates.FRIEND_REQUEST_CANCELLED:
        case UserRelationEventStates.FRIEND_REQUEST_REJECTED:
            stateDecoration = <CancelOutlinedIcon />;
            break;
        case UserRelationEventStates.FRIEND_REQUEST_PENDING:
            stateDecoration = isOwnRequest
                              ? <HourglassEmptyOutlinedIcon />
                              : <FriendshipRequestMenuButton request={request} />;
            break;
    }

    return (
        <ListItem
            {...props}
        >
            {displayUser
             ? <>
                 <ListItemAvatar>
                     <UserAvatar userId={displayUser?.id} />
                 </ListItemAvatar>
                 <ListItemText
                     primary={displayUser?.username}
                     secondary={dayjs(request.createdAt).format()}
                 />
                 {stateDecoration}
             </>
             : <></>
            }
        </ListItem>
    )
}

FriendshipRequestListItem.propTypes = {
    request: PropTypes.object.isRequired,
}

export default FriendshipRequestListItem;