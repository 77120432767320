import { EntityTypes } from "types/enums/entity-types";

export class ShareLink {
    id = '';
    createdAt = '';
    owner = '';
    targetId = '';
    shareType = '';
    targetUserId = '';
    targetUserHandle = '';
    state = '';
    closedAt = '';
    entityType = EntityTypes.SHARE_LINK;
}

export const ShareLinkState = {
    CANCELLED: 'CANCELLED',
    FULFILLED: 'FULFILLED',
    PENDING: 'PENDING',
}

export const ShareLinkShareType = {
    ENTITY: 'ENTITY',
    FRIEND: 'FRIEND',
    GROUP: 'GROUP',
}
