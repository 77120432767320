import { Endpoint, schema } from '@data-client/endpoint';
import axios from 'axios';
import { UserResource } from 'store/dataclient/user-entity';
import { EntityTypes } from 'types/enums/entity-types';
import UserRelationEvent from 'types/user-relation-event';
import { dataclientController as restCtrl } from 'store/dataclient-store';

import { baseUrl } from "store/backend-config";

export const relationEventBaseUrl = `${baseUrl}/friends/requests`;
export const friendsBaseUrl = `${baseUrl}/friends`;

export const UserRelationEventEntity = schema.Entity(
    UserRelationEvent,
    {
        key: EntityTypes.USER_RELATION_EVENT,
    },
)

export const UserRelationEventResource = {
    get: new Endpoint(
        ({ id }) => axios.get(`${relationEventBaseUrl}/${id}`).then(resp => resp.data)
        , {
            name: 'userRelEventGet',
            schema: UserRelationEventEntity,
        },
    ),
    list: new Endpoint(
        () => axios.get(`${relationEventBaseUrl}`).then(resp => resp.data)
        , {
            name: 'userRelEventList',
            schema: [UserRelationEventEntity],
            pollFrequency: 2 * 60 * 1000,
        },
    ),
    createRequest: new Endpoint(
        ({ targetId }) => axios.post(`${relationEventBaseUrl}`, { targetId: targetId })
                               .then(resp => {
                                   void restCtrl.expireAll(UserRelationEventResource.list)
                                   return resp.data
                               })
        , {
            name: 'userRelEventCreateReq',
            schema: UserRelationEventEntity,
            // update: (newRequest) => ({
            //     [UserRelationEventResource.list.key()]: events => [newRequest, ...(events.filter(event => event.id !== newRequest.id))],
            // }),
        },
    ),
    acceptRequest: new Endpoint(
        ({ id }) => axios.post(`${relationEventBaseUrl}/${id}/accept`)
                         .then(resp => {
                             void restCtrl.expireAll(UserResource.listFriends);
                             return resp.data
                         })
        , {
            name: 'userRelEventAccept',
            schema: UserRelationEventEntity,
        },
    ),
    rejectRequest: new Endpoint(
        ({ id }) => axios.post(`${relationEventBaseUrl}/${id}/reject`)
                         .then(resp => resp.data)
        , {
            name: 'userRelEventReject',
            schema: UserRelationEventEntity,
        },
    ),
    cancelRequest: new Endpoint(
        ({ id }) => axios.post(`${relationEventBaseUrl}/${id}/cancel`)
                         .then(resp => resp.data)
        , {
            name: 'userRelEventCancel',
            schema: UserRelationEventEntity,
        },
    ),
    cancelFriendship: new Endpoint(
        ({ targetId }) => axios.delete(`${friendsBaseUrl}/${targetId}`).then(resp => {
            void restCtrl.expireAll(UserResource.listFriends);
            return resp.data;
        })
        , {
            name: 'userRelFriendshipCancel',
            schema: UserRelationEventEntity,
        },
    ),
}
