import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * MUI Dialog to confirm some action
 * @param {string} [title] dialog title
 * @param {string} [text] optional description
 * @param {boolean} open dialog state
 * @param {string} [confirmActionLabel='OK'] label for confirmation button
 * @param {function()} onClose callback to call when dialog closes
 * @param {function()} onConfirm callback called when user confirms the action
 * @param [props] passed to Dialog
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationDialog = ({ title, text, confirmActionLabel, open, onClose, onConfirm, ...props }) => {
    const { t } = useTranslation();

    if ( !confirmActionLabel)
        confirmActionLabel = t('ok');

    const handleDialogConfirm = function() {
        onConfirm();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...props}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {text && <DialogContentText>{text}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined'>{t('cancel')}</Button>
                <Button onClick={handleDialogConfirm} variant='contained'>{confirmActionLabel}</Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmationDialog.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    confirmActionLabel: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
}

export default ConfirmationDialog;