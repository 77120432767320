import { ButtonBase } from "@mui/material";
import ImageUploadDialog from "components/common/ImageUploadDialog";
import PropTypes from "prop-types";
import { useState } from "react";


const ImageUploadButtonNew = ({ parentId, fileKind, onClick, onUpload, children, isAvatar = false, ...props }) => {
    const [triggerFileInput, setTriggerFileInput] = useState(false);

    return (
        <>
            <ButtonBase
                onClick={() => {
                    setTriggerFileInput(true);
                }}
                // sx={{ ...props.sx }}
                {...props}
            >
                {children}
            </ButtonBase>
            <ImageUploadDialog
                parentId={parentId}
                fileKind={fileKind}
                triggerFileInput={triggerFileInput}
                onClose={() => setTriggerFileInput(false)}
                onUpload={onUpload}
                isAvatar={isAvatar}
            />
        </>
    )
}

ImageUploadButtonNew.propTypes = {
    parentId: PropTypes.string,
    fileKind: PropTypes.string,
    onClick: PropTypes.func,
    onUpload: PropTypes.func,
    children: PropTypes.any,
    isAvatar: PropTypes.bool,
    sx: PropTypes.object,
    parentBoxRef: PropTypes.object,
}

export default ImageUploadButtonNew;
