import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ShareLinkRoutes from "./ShareLinkRoutes";
import ProfileMenuRoutes from './ProfileMenuRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, ShareLinkRoutes, ProfileMenuRoutes]);
}