import { useController } from "@data-client/react";
import { useDeepCompareMemoize } from 'hooks/deep-compare-hooks';
import { useRunningTimerSessions } from 'hooks/timer-session-hooks';
import { useEffect } from "react";
import { TimerSessionResource } from "store/dataclient/timer-session-resource";
import { computeTimerSessionDuration } from "utils/timer-value-helpers";

export function useMainTicker() {
    const restCtrl = useController();
    const tmpSessions = useRunningTimerSessions();

    useEffect(() => {
        if ( !tmpSessions?.length)
            return;

        const interval = setInterval(
            () => {
                tmpSessions.forEach(
                    session => session.duration = computeTimerSessionDuration(session),
                );
                void restCtrl.fetch(TimerSessionResource.putIntoStore, { sessions: tmpSessions });
            }, [1000]);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, useDeepCompareMemoize([tmpSessions]));
}
