import { Endpoint, schema } from "@data-client/endpoint";
import axios from "axios";
import { EntityTypes } from "types/enums/entity-types";
import UserEffort from "types/user-effort";

import { baseUrl } from "store/backend-config";

const effortBaseUrl = `${baseUrl}/timers/sessions/efforts`;

export const UserEffortEntity = schema.Entity(
    UserEffort,
    {
        key: EntityTypes.USER_EFFORT,
        pk: (value) => value.parentId + ':' + value.ownerId,
    }
);

export const UserEffortResource = {
    list: new Endpoint(
        () => axios.get(`${effortBaseUrl}`).then(resp => resp.data),
        {
            name: 'userEffortList',
            pollFrequency: 60 * 1000,
        }
    )
}
