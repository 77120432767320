import { AsyncBoundary } from "@data-client/react";
import React from "react";
import { Box} from '@mui/material';
import Profile from './Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {

    return (
        <>
            <Box sx={{ width: '100%', ml: 1 }} />
            {/* <Notification /> */}
            <AsyncBoundary>
                <Profile />
            </AsyncBoundary>
            {/* {matchesXs && <MobileSection />} */}
        </>
    );
};

export default HeaderContent;
