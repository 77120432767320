import { IconButton } from "@mui/material";
import { useCopyToClipboard } from "hooks/clipboard-hooks";
import PropTypes from "prop-types";

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const ClipboardCopyButton = ({ text, ...props }) => {
    const [_copiedText, copyTrigger] = useCopyToClipboard();

    return (
        <IconButton
            onClick={() => copyTrigger(text)}
            {...props}
        >
            <ContentCopyOutlinedIcon />
        </IconButton>
    )
}

ClipboardCopyButton.propTypes = {
    text: PropTypes.string.isRequired,
}

export default ClipboardCopyButton;
