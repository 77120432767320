import { Endpoint } from "@data-client/endpoint";
import axios from "axios";
import { baseUrl } from "store/backend-config";
import { ApiMessageEntity } from "store/dataclient/api-message-entity";
import { FileInfoEntity } from "store/dataclient/file-info-entity";

import { dataclientController as restCtrl } from "store/dataclient-store";

export const fileBaseUrl = `${baseUrl}/files`;

export const FileResource = {
    upload: new Endpoint(
        ({ parentId = null, file, kind, filename }) => {
            const formData = new FormData();

            if (filename)
                formData.append('file', file, filename);
            else
                formData.append('file', file);

            formData.append('fileKind', kind);
            if (parentId)
                formData.append('parentId', parentId);

            // possible file deletions on server side
            void restCtrl.invalidate(FileResource.listEntityFiles, { entityId: parentId });

            return axios.post(`${fileBaseUrl}`, formData).then(response => response.data);
        },
        {
            name: 'fileUpload',
            schema: FileInfoEntity,
            sideEffect: true,
        }
    ),
    listEntityFiles: new Endpoint(
        ({ entityId }) => axios.get(`${fileBaseUrl}/entity/${entityId}`).then(response => response.data),
        {
            name: 'fileEntityList',
            schema: [FileInfoEntity],
        }
    ),
    delete: new Endpoint(
        ({ id }) => axios.delete(`${fileBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'fileDelete',
            schema: ApiMessageEntity,
            sideEffect: true,
        }
    )
}
