import { schema } from "@data-client/endpoint";
import { EntityTypes } from "types/enums/entity-types";
import { ShareLink } from "types/share-link";

export const ShareLinkEntity = schema.Entity(
    ShareLink,
    {
        key: EntityTypes.SHARE_LINK,
    }
)
