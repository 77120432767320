import {createSlice} from '@reduxjs/toolkit';

export const DisplayModes = {
    DARK: "dark",
    LIGHT: "light",
}

const localStorageState = JSON.parse(localStorage.getItem("themeState"));

const initialState = {
    displayMode: DisplayModes.DARK,
    ...localStorageState
}

const sliceTheme = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleDisplayMode(state, _action) {
            state.displayMode = state.displayMode === DisplayModes.LIGHT ? DisplayModes.DARK : DisplayModes.LIGHT;
            localStorage.setItem("themeState", JSON.stringify(state));
        },
    }
})

export default sliceTheme.reducer;

export const { toggleDisplayMode } = sliceTheme.actions;
