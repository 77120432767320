import { useDebounce } from '@data-client/hooks';
import { ClickAwayListener, InputAdornment, List, TextField, IconButton } from '@mui/material';
import UserListItem from 'components/users/UserListItem';
import { useUserSearch } from 'hooks/user-hooks';
import { useEffect, useId, useRef, useState } from 'react';
import { useMobileDetector } from 'hooks/theme-hooks';
import PropTypes from 'prop-types';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

/**
 * Compocite widget to search users,
 * MUI TextField with a popper
 * @param [props] passed to TextField
 * @returns {JSX.Element}
 * @constructor
 */
const UserSearchWidget = ({ setIsSearching, ...props }) => {
    const [searchHint, setSearchHint] = useState('');
    const debouncedHint = useDebounce(searchHint, 500);
    const foundUsers = useUserSearch(debouncedHint);
    const mobileDevice = useMobileDetector();

    const popperAnchor = useRef(null);
    const textFieldId = useId();
    const [isResultsListOpen, setResultsListOpen] = useState(false);

    useEffect(() => {
        if (foundUsers?.length)
            setResultsListOpen(true);
    }, [foundUsers]);

    useEffect(() => {
        setIsSearching(foundUsers?.length > 0);
    }, [foundUsers, setIsSearching]);

    const handleTextFieldClick = () => {
        setIsSearching(true);
    };

    const clearSearch = () => {
        setSearchHint('');
        setIsSearching(false);
    };

    const handleClickAway = () => {
        if (!searchHint) {
            setIsSearching(false);
            setResultsListOpen(false);
        }
    };


    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    ref={popperAnchor}
                    id={textFieldId}
                    value={searchHint}
                    fullWidth={mobileDevice}
                    autoComplete='off'
                    onChange={event => setSearchHint(event.target.value)}
                    onClick={() => {
                        handleTextFieldClick();
                        if (foundUsers?.length > 0 && !isResultsListOpen)
                            setResultsListOpen(true);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchOutlinedIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchHint && (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='clear search'
                                    onClick={clearSearch}
                                    edge='end'
                                >
                                    <CancelIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...props}
                    sx={{ pl: 1, pr: 1, pt: 2 }}
                />
            </ClickAwayListener>
            <List>
                {foundUsers.map((foundUser) => (
                    <UserListItem key={foundUser.id} userId={foundUser.id} />
                ))}
            </List>
        </>
    )
}
UserSearchWidget.propTypes = {
    setIsSearching: PropTypes.func.isRequired,
};

export default UserSearchWidget;