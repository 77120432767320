import ThreeDotsMenuButton from 'components/common/ThreeDotsMenuButton';
import PropTypes from "prop-types";
import { lazy, useMemo } from 'react';

const TaskMenu = lazy(() => import('../tasks/TaskMenu'));
const ProjectMenu = lazy(() => import('../projects/ProjectMenu'));
const TimerMenu = lazy(() => import('../timers/TimerMenu'));

const selectMenu = (entityId, entityType) => {
    switch (entityType) {
        case 'task':
            return <TaskMenu taskId={entityId} />;
        case 'project':
            return <ProjectMenu projectId={entityId} />;
        case 'timer':
            return <TimerMenu timerId={entityId} />;
        default:
            return null;
    }
};

const EntityMenuButton = ({ entityId, entityType, ...props }) => {
    const selectedMenu = useMemo(() => selectMenu(entityId, entityType), [entityId, entityType]);

    return (
        <ThreeDotsMenuButton
            menuContents={selectedMenu}
            {...props}
        />
    );
};

EntityMenuButton.propTypes = {
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
}

export default EntityMenuButton;
