import { useLive } from '@data-client/react';
import { memo } from 'react';
import { TimerSessionResource } from 'store/dataclient/timer-session-resource';

const RunningTimerSessionSubscriber = () => {
    void useLive(TimerSessionResource.listRunning);
    return (
        <>
        </>
    )
}

export default memo(RunningTimerSessionSubscriber);