import { useMobileDetector } from 'hooks/theme-hooks';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';
import { memo } from 'react';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ marginLeft }) => {
    const theme = useTheme();
    const mobileDevice = useMobileDetector();

    // common header
    const mainHeader = (
        <Toolbar>
            <HeaderContent />
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!mobileDevice ? (
                <AppBarStyled marginLeft={marginLeft} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar} >{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    marginLeft: PropTypes.number
};

export default memo(Header);
