import { schema } from "@data-client/endpoint";
import { TimerSessionEntity } from "store/dataclient/timer-session-entity";
import { EntityTypes } from "types/enums/entity-types";
import HanaEntity from "types/hana-entity";

export const HanaEntityImpl = schema.Entity(
    HanaEntity,
    {
        key: EntityTypes.ENTITY
    }
)

HanaEntityImpl.schema = {
    children: [HanaEntityImpl],
    timerSessions: [TimerSessionEntity],
}
