import { Avatar, Chip } from "@mui/material";
import UserAvatar from "components/users/UserAvatar";
import PropTypes from "prop-types";
import { formatDuration } from "utils/duration-formatter";

const TimerChipBasic = ({ value, userId, ...props }) => {
    const stringValue = formatDuration(value);
    const avatar = userId
        ? <UserAvatar userId={userId} />
        : <Avatar>N</Avatar>
    ;
    return (
        <Chip
            avatar={avatar}
            variant='outlined'
            sx={{ m: '2px' }}
            label={stringValue}
            {...props}
        />
    );
};

TimerChipBasic.propTypes = {
    value: PropTypes.number,
    userId: PropTypes.string,
}

export default TimerChipBasic;
