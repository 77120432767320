import PropTypes from 'prop-types';

import PopperMenuIconButton from 'components/common/PopperMenuIconButton';
import FriendshipRequestMenu from 'components/users/FriendshipRequestMenu';

import MoreVertIcon from '@mui/icons-material/MoreVert';

/**
 * MUI IconButton, opens popper with friendship request related actions
 * @param {object} request friendship request object
 * @param [props] passed to IconButton
 * @returns {JSX.Element}
 * @constructor
 */
const FriendshipRequestMenuButton = ({ request, ...props }) => {
    return (
        <PopperMenuIconButton
            popperContents={<FriendshipRequestMenu request={request} />}
            {...props}
        >
            <MoreVertIcon />
        </PopperMenuIconButton>
    )
}

FriendshipRequestMenuButton.propTypes = {
    request: PropTypes.object.isRequired,
}

export default FriendshipRequestMenuButton;