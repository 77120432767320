import { SvgIcon } from "@mui/material";

const HanatulLogoIcon = ({ ...props }) => {
    return (
        <SvgIcon 
        {...props}
        sx={{ fontSize: '20px' }}
        >
            <g stroke='#777' strokeWidth='.2'>
                <rect x='3.6' y='3' width='16.8' height='2.4' />
                <rect x='2.4' y='18.6' width='19.2' height='2.4' />
                <rect x='7.2' y='10.8' width='9.6' height='2.4' />
            </g>
        </SvgIcon>
    )
}

export default HanatulLogoIcon;
