import { createSlice } from '@reduxjs/toolkit';
import { CardTypes } from 'types/enums/card-types';

export const projectCardTypeSlice = createSlice({
    name: 'projectCardType',
    initialState: {
        value: CardTypes.BOX,
    },
    reducers: {
        toggleTimerCardType: (state) => {
            state.value = state.value === CardTypes.BOX ? CardTypes.LINE : CardTypes.BOX;
        },
    },
});

export const { toggleProjectCardType } = projectCardTypeSlice.actions;

export default projectCardTypeSlice.reducer;