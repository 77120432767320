import PropTypes from 'prop-types';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useFriendshipCheck, useFriendshipControls } from 'hooks/user-friendship-hooks';
import { useTranslation } from 'react-i18next';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';

/**
 * MUI List with user-related actions
 * @param {object} user user object
 * @param {function()} [onClose] callback to close parent container
 * @param [props] passed to List
 * @returns {JSX.Element}
 * @constructor
 */
const UserMenu = ({ user, onClose, ...props }) => {
    const { t } = useTranslation();
    const isFriend = useFriendshipCheck(user.id);
    const { friendshipCancel, friendshipRequest } = useFriendshipControls();
    // create stub for undefined onClose
    if (typeof onClose !== 'function') {
        onClose = () => null;
    }

    return (
        <List
            {...props}
        >

            {isFriend
             ? <ListItemButton onClick={() => friendshipCancel(user.id) && onClose()}>
                 <ListItemIcon>
                     <PersonRemoveOutlinedIcon />
                 </ListItemIcon>
                 <ListItemText primary={t('remove')} />
             </ListItemButton>
             : <ListItemButton onClick={() => friendshipRequest(user.id) && onClose()}>
                 <ListItemIcon>
                     <PersonAddOutlinedIcon />
                 </ListItemIcon>
                 <ListItemText primary={t('add_friend')} />
             </ListItemButton>
            }
        </List>
    )
}

UserMenu.propTypes = {
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func,
}

export default UserMenu;