import NavigationMenu from "layout/Navigation/NavigationMenu";
// import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
    <SimpleBar
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
            }
        }}
    >
        <NavigationMenu/>
        {/*<Navigation />*/}
    </SimpleBar>
);


export default DrawerContent;
