import TimerChipBasic from "components/timers/chips/TimerChipBasic";
import { useUserEntityEffort } from "hooks/user-effort-hooks";
import PropTypes from "prop-types";
import { formatDuration } from "utils/duration-formatter";

const TimerChipEntityUser = ({ entityId, userId, isTextLine = false, ...props }) => {
    const timerValue = useUserEntityEffort(entityId, userId);
    const stringValue = formatDuration(timerValue);
    return isTextLine
        ?   <>{ stringValue }</>
        :
        <TimerChipBasic
            userId={userId}
            value={timerValue}
            {...props}
        />;
};

export default TimerChipEntityUser;

TimerChipEntityUser.propTypes = {
    entityId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    isTextLine: PropTypes.bool,
}
