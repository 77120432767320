import React, { Suspense } from 'react';
import { List, Drawer } from "@mui/material";
import NavigationMenuListItem from "layout/Navigation/NavigationMenuListItem";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';

import FriendsPage from 'pages/extra-pages/FriendsPage';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ChecklistIcon from '@mui/icons-material/Checklist';

import { openDrawer, toggleFriendsDrawer, setFriendsDrawerOpen } from 'store/reducers/slice-menu';


const NavigationMenu = ({ ...props }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const friendsDrawerOpen = useSelector((state) => state.menu.friendsDrawerOpen);


    const handleFriendsClick = () => {
        dispatch(toggleFriendsDrawer());
        dispatch(openDrawer({ drawerOpen: friendsDrawerOpen }));
    };

    const handleMenuClick = () => {
        dispatch(setFriendsDrawerOpen(false));
        dispatch(openDrawer({ drawerOpen: true }));
    }

    const iconStyles = {
        color: friendsDrawerOpen ? theme.palette.primary.main : theme.palette.icon.main,
    };

    return (
        <>
            <List {...props}>
                <NavigationMenuListItem title={t('dashboard')} onClick={handleMenuClick} url='/dashboard/default' icon={<DashboardOutlinedIcon sx={{ color: theme.palette.icon.main }} />} />
                <NavigationMenuListItem title={t('timers')} onClick={handleMenuClick} url='/timers/grid' icon={<TimelapseIcon />} />
                <NavigationMenuListItem title={t('projects')} onClick={handleMenuClick} url='/projects' icon={<ChecklistIcon />} sx={{ color: theme.palette.icon.main }} />
                <NavigationMenuListItem title={t('friends')} onClick={handleFriendsClick} icon={<GroupsOutlinedIcon sx={iconStyles} />} />
            </List>
            <Drawer
                anchor="left"
                variant='permanent'
                open={friendsDrawerOpen}
                sx={{
                    width: friendsDrawerOpen ? 300 : 0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: friendsDrawerOpen ? 300 : 0,
                        minWidth: friendsDrawerOpen ? undefined : 0,
                        whiteSpace: 'nowrap',
                        marginLeft: friendsDrawerOpen ? '60px' : '-300px',
                        backgroundColor: theme.palette.background.default,
                        boxShadow: 'none',
                        borderRight: `1px solid ${theme.palette.divider}`,
                        transition: theme.transitions.create('width', { // Add a transition for smooth opening/closing
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    },
                }}
            >
                {friendsDrawerOpen && (
                    <Suspense fallback={<div>Loading friends...</div>}>
                        <FriendsPage />
                    </Suspense>
                )}
            </Drawer>
        </>
    )
}

export default NavigationMenu;
