import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ShareLinkCreationDialog from "components/shares/share-links/ShareLinkCreationDialog";
import { ShareableTargetListItem } from "components/shares/ShareableTargetListItem";
import { useShareableTargets, useShares, useShareTriggers } from "hooks/share-hooks";
import PropTypes from "prop-types";

import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import { useState } from "react";

export const ShareableTargetList = ({ parentId, disabled = false, ...props }) => {
    const targets = useShareableTargets();
    const existingShares = useShares(parentId);
    const [shareTrigger, unshareTrigger] = useShareTriggers(parentId);

    const [linkDialogOpen, setLinkDialogOpen] = useState(false);

    const toggleShare = (shareWith) => {
        if (existingShares?.includes(shareWith))
            unshareTrigger(shareWith);
        else
            shareTrigger(shareWith);
    }

    return (
        <>
            <List {...props}>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => setLinkDialogOpen(true)}>
                        <ListItemIcon>
                            <AddLinkOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary='share_link_create' />
                    </ListItemButton>
                </ListItem>
                {targets.map(target =>
                    <ShareableTargetListItem
                        key={target.id}
                        target={target}
                        selected={existingShares?.includes(target.id)}
                        onClick={() => toggleShare(target.id)}
                        disabled={disabled}
                    />
                )}
            </List>
            <ShareLinkCreationDialog
                open={linkDialogOpen}
                parentId={parentId}
                onClose={() => setLinkDialogOpen(false)}
            />
        </>
    );
};

ShareableTargetList.propTypes = {
    parentId: PropTypes.string,
    disabled: PropTypes.bool,
}
