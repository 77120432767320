const defaultColors = {
    "red": [
        "#fff1f0",
        "#ffccc7",
        "#ffa39e",
        "#ff7875",
        "#ff4d4f",
        "#f5222d",
        "#cf1322",
        "#a8071a",
        "#820014",
        "#5c0011"
    ],
    "volcano": [
        "#fff2e8",
        "#ffd8bf",
        "#ffbb96",
        "#ff9c6e",
        "#ff7a45",
        "#fa541c",
        "#d4380d",
        "#ad2102",
        "#871400",
        "#610b00"
    ],
    "orange": [
        "#fff7e6",
        "#ffe7ba",
        "#ffd591",
        "#ffc069",
        "#ffa940",
        "#fa8c16",
        "#d46b08",
        "#ad4e00",
        "#873800",
        "#612500"
    ],
    "gold": [
        "#fffbe6",
        "#fff1b8",
        "#ffe58f",
        "#ffd666",
        "#ffc53d",
        "#faad14",
        "#d48806",
        "#ad6800",
        "#874d00",
        "#613400"
    ],
    "yellow": [
        "#feffe6",
        "#ffffb8",
        "#fffb8f",
        "#fff566",
        "#ffec3d",
        "#fadb14",
        "#d4b106",
        "#ad8b00",
        "#876800",
        "#614700"
    ],
    "lime": [
        "#fcffe6",
        "#f4ffb8",
        "#eaff8f",
        "#d3f261",
        "#bae637",
        "#a0d911",
        "#7cb305",
        "#5b8c00",
        "#3f6600",
        "#254000"
    ],
    "green": [
        "#f6ffed",
        "#d9f7be",
        "#b7eb8f",
        "#95de64",
        "#73d13d",
        "#52c41a",
        "#389e0d",
        "#237804",
        "#135200",
        "#092b00"
    ],
    "cyan": [
        // "#fff0f0",
        // "#ffedef",
        // "#ffc4cb",
        // "#ff9caa",
        // "#f56e87",
        // "#e94469",
        // "#c22f53",
        // "#9c1e41",
        // "#751030",
        // "#4f0a22",
        // "#290613",
        // "#2e0816",
        "#e0f8f7",
        "#b3edeb",
        "#82e1e0",
        "#50d4d5",
        "#29cace",
        "#00c1c9",
        "#00b1b7",
        "#009b9e",
        "#018787",
        "#05635e",
        "#03403E",
        "#e6fffb",
        "#02302F"
    ],
    "blue": [
        "#e6f7ff",
        "#bae7ff",
        "#91d5ff",
        "#69c0ff",
        "#40a9ff",
        "#1890ff",
        "#096dd9",
        "#0050b3",
        "#003a8c",
        "#002766"
    ],
    "geekblue": [
        "#f0f5ff",
        "#d6e4ff",
        "#adc6ff",
        "#85a5ff",
        "#597ef7",
        "#2f54eb",
        "#1d39c4",
        "#10239e",
        "#061178",
        "#030852"
    ],
    "purple": [
        "#f9f0ff",
        "#efdbff",
        "#d3adf7",
        "#b37feb",
        "#9254de",
        "#722ed1",
        "#531dab",
        "#391085",
        "#22075e",
        "#120338"
    ],
    "magenta": [
        "#fff0f6",
        "#ffd6e7",
        "#ffadd2",
        "#ff85c0",
        "#f759ab",
        "#eb2f96",
        "#c41d7f",
        "#9e1068",
        "#780650",
        "#520339"
    ],
    "grey": [
        "#ffffff",
        "#fafafa",
        "#f5f5f5",
        "#f0f0f0",
        "#d9d9d9",
        "#bfbfbf",
        "#3c3c3c",
        "#595959",
        "#262626",
        "#141414",
        "#000000",
        "#fafafa",
        "#bfbfbf",
        "#434343",
        "#1f1f1f",
        "#fafafb",
        "#e6ebf1"
    ]
}

export default defaultColors;