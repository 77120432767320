import { useEntityTimer } from "hooks/timer-hooks";
import PropTypes from "prop-types";
import { useTheme, alpha } from '@mui/material/styles';
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton } from "@mui/material";
import { useCallback, useMemo } from 'react';

const TimerControlButton = ({ parentId, variant = 'line', sx, ...props }) => {
    const theme = useTheme();
    const { timerIsStarted, timerRun, timerPause } = useEntityTimer(parentId);

    // Define styles for each variant
    const iconStyle = useCallback((isPauseIcon) => {
        let baseStyle = {
            height: variant === 'box' ? '45px' : '35px',
            width: variant === 'box' ? '45px' : '35px',
            strokeWidth: variant === 'box' ? 0 : 0.4,
            transition: 'all 0.4s ease', // smooth transition for size change
        };

        // Additional hover styles for 'box' variant
        let hoverStyle = {};
        if (variant === 'box') {
            hoverStyle = {
                '&:hover': {
                    transform: 'scale(1.15)',
                },
            };
        }

        return isPauseIcon
               ? { ...baseStyle, ...hoverStyle, color: theme.palette.warning.main, stroke: theme.palette.warning.dark }
               : { ...baseStyle, ...hoverStyle, color: theme.palette.primary.main, stroke: theme.palette.primary.dark };
    }, [variant, theme]);

    const buttonStyle = useMemo(() => ({
        ...(variant === 'box' ? {
            // IconButton styles for 'box' variant
            width: '65px',
            height: '65px',
            transition: 'all 0.4s ease',
            borderRadius: '50%', // Makes the IconButton rounded
            backgroundColor: alpha(theme.palette.secondary.light, 0.4),
            transform: 'scale(1)',
            '&:hover': {
                transform: 'scale(1.15)',
                backgroundColor: alpha(theme.palette.mode === 'dark' ? theme.palette.secondary.lighter : theme.palette.secondary.dark, 0.7),
            },
        } : {
            // Styles for other variants
        }),
        ...sx,
    }), [variant, theme, sx]);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        timerIsStarted ? timerPause() : timerRun();
    };

    return (
        <IconButton
            // wtf
            // disabled={state ? typeof onPause !== 'function' : typeof onRun !== 'function'}
            onClick={handleClick}
            {...props}
            sx={buttonStyle}>
            {timerIsStarted
             ? <PauseIcon sx={iconStyle(true)} />
             : <PlayArrowIcon sx={iconStyle(false)} />
            }
        </IconButton>
    );
};

TimerControlButton.propTypes = {
    parentId: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['line', 'box']),
    sx: PropTypes.object,
};

export default TimerControlButton;
