import { schema } from "@data-client/endpoint";
import { EntityTypes } from "types/enums/entity-types";
import TimerSession from "types/timer-session";

export const TimerSessionEntity = schema.Entity(
    TimerSession,
    {
        key: EntityTypes.TIMER_SESSION
    }
)
