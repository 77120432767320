import PropTypes from "prop-types";
import React from "react";
import {Box, FormLabel} from "@mui/material";
import {useTheme} from '@mui/material/styles';

const OutlinedBox = ({ label, color, children, ...props }) => {
    const theme = useTheme();
    return (
        <Box sx={{ border: 1, borderColor: color, borderRadius: '6px'}} {...props}>
            <FormLabel
                sx={{
                    marginLeft: "0.71em",
                    marginTop: "-0.71em",
                    paddingLeft: "0.44em",
                    paddingRight: '0.44em',
                    zIndex: 2,
                    backgroundColor: (theme) => theme.palette.background.default,
                    border: 1,
                    borderColor: theme.palette.secondary.lighter,
                    position: "absolute",
                    fontSize: "0.95em",
                    borderRadius: "4px",
                    color: theme.palette.text.primary,
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '160px',
                }}>{label}</FormLabel>
            <Box
                sx={{
                    position: 'relative',
                    borderRadius: '6px',
                    borderColor: color,
                    fontSize: '0.875rem',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'auto'
                    }}
                >
                    {children}
                </Box>
                <fieldset aria-hidden={"true"} style={{
                    textAlign: 'left',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    top: '-5px',
                    left: 0,
                    margin: 0,
                    padding: '0 8px',
                    pointerEvents: 'none',
                    borderRadius: 'inherit',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    overflow: 'hidden',
                    minWidth: '0%',
                    borderColor: theme.palette.background.default,
                }}
                >
                    <legend style={{
                        float: 'unset',
                        overflow: 'hidden',
                        display: 'block',
                        width: 'auto',
                        padding: 0,
                        height: '11px',
                        fontSize: '0.75em',
                        visibility: 'hidden',
                        maxWidth: '100%',
                        transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms',
                        whiteSpace: 'nowrap',
                    }}><span>{label}</span></legend>
                </fieldset>
            </Box>
        </Box>
    );
}

export default OutlinedBox;

OutlinedBox.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    children: PropTypes.any,
}
