// import 'utils/wdyr';
import { memo, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

import { Provider as ReduxProvider } from 'react-redux';
import { ExternalCacheProvider } from "@data-client/redux";
import { AuthResource } from "store/dataclient/auth-resource";

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import { dataclientController, dataclientSelector } from "store/dataclient-store";

await dataclientController.fetch(AuthResource.getCurrentUser);
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const MemoizedCacheProvider = memo(ExternalCacheProvider);

root.render(
    <StrictMode>
        <MemoizedCacheProvider store={store} selector={dataclientSelector} controller={dataclientController}>
            <ReduxProvider store={store}>
                <BrowserRouter basename='/'>
                    <App />
                    <span id='modal' />
                </BrowserRouter>
            </ReduxProvider>
        </MemoizedCacheProvider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
