export function extractDuration(durationSeconds) {
    let tmp, seconds, minutes, hours, days;
    tmp = durationSeconds;
    seconds = tmp % 60;
    tmp = (tmp - seconds) / 60;
    minutes = tmp % 60;
    tmp = (tmp - minutes) / 60;
    let fullHours = tmp * 1;
    hours = tmp % 24;
    tmp = (tmp - hours) / 24;
    days = tmp;
    return { seconds, minutes, hours, days, fullHours };
}

export function formatDuration(durationSeconds) {
    if ( !durationSeconds)
        durationSeconds = 0;
    const { seconds, minutes, fullHours, _days } = extractDuration(durationSeconds);
    let secSeconds = String(seconds).padStart(2, '0');
    let secMinutes = String(minutes).padStart(2, '0');
    let secHours = String(fullHours).padStart(2, '0');
    let out = `${secHours}:${secMinutes}:${secSeconds}`;
    return out;
}