import { useMainTicker } from 'hooks/main-ticker-hook';
import { memo } from 'react';

/**
 * Background timer ticker. Only one instance should be present in the DOM at a time.
 * @returns {JSX.Element}
 * @constructor
 */
const MainTimerTicker = () => {
    useMainTicker();
    return (
        <></>
    )
}

export default memo(MainTimerTicker);