import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { Box, ClickAwayListener, IconButton, Input, InputAdornment, Typography } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import ModeIcon from "@mui/icons-material/Mode";
import CloseIcon from '@mui/icons-material/Close';


/**
 * Toggleable text display/input component
 *
 * @param {string} value Initial value to display/edit
 * @param {function(string)} onCommit callback to commit changes
 * @param {string} [props.variant="box-card"] The visual variant of the component, influencing its styling.
 * @param {boolean} [props.isEditing=false] Determines whether the component is in edit mode initially.
 * @param {Object} [props.fontStyles={}] Custom CSS properties for styling the text, such as fontSize and color.
 * @param {props} props passed to Box container
 * @returns {Element}
 * @constructor
 */
const EntityFieldTextInput = ({ value, variant = "box-card", fontStyles: propFontStyles = {}, isEditing: propIsEditing = false, onCommit, ...props }) => {
    const [isEditing, setEditing] = useState(propIsEditing);
    const [activeValue, setActiveValue] = useState(value);
    const theme = useTheme();

    useEffect(() => {
        setActiveValue(value)
    }, [value]);

    useEffect(() => {
        setEditing(propIsEditing);
    }, [propIsEditing]);

    const handleCommit = () => {
        if (activeValue?.trim())
            onCommit(activeValue.trim());
        setEditing(false);
    }

    const handleCancel = () => {
        setEditing(false);
        setActiveValue(value);
    }

    const boxStyles = {
        display: 'flex',
        alignItems: 'center',
        // backgroundColor: variant === "box-card" ? (theme) => alpha(theme.palette.secondary.main, 0.7) : "transparent",
        backgroundColor: "transparent",
        borderRadius: variant === "box-card" ? "2px" : "0",
        paddingLeft: variant === "box-card" ? "0.44em" : "20px",
        zIndex: 1,
    };

    const defaultFontStyles = {
        fontSize: '1.5em',
        color: variant === "box-card" ? '#fff' : theme.palette.text.primary,
    };

    const fontStyles = {
        ...defaultFontStyles,
        ...propFontStyles,

    }

    return (
        <Box
            sx={boxStyles}
            {...props}
        >
            {isEditing
                ? <ClickAwayListener
                    onClickAway={handleCancel}
                >
                    <Input
                        value={activeValue}
                        // onBlur={() => setEditing(false)}
                        onChange={event => setActiveValue(event.target.value)}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton variant={variant} onClick={handleCommit}>
                                    <DoneIcon />
                                </IconButton>
                                <IconButton variant={variant} onClick={handleCancel}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        disableUnderline
                        multiline
                        autoFocus
                        sx={{
                            ...fontStyles,
                            padding: '2px 0',
                            '& .MuiInputBase-input': {
                                paddingTop: 0,
                                paddingBottom: '2px',
                            },
                        }}
                    />
                </ClickAwayListener>
                : <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap', }}>
                    <Typography
                        sx={{
                            ...fontStyles,
                            whiteSpace: 'normal',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {activeValue}
                    </Typography>
                    <IconButton variant={variant} onClick={() => setEditing(true)}>
                        <ModeIcon sx={{
                            position: 'absolute',
                            top: '2px',
                            right: '8px',
                            height: '15px'
                        }} />
                    </IconButton>
                </Box>
            }
        </Box>
    )
}

EntityFieldTextInput.propTypes = {
    value: PropTypes.string,
    onCommit: PropTypes.func,
    variant: PropTypes.string,
    isEditing: PropTypes.bool,
    fontStyles: PropTypes.object,
}

export default EntityFieldTextInput;
