import { ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import GroupAvatar from 'components/shares/GroupAvatar';
import UserAvatar from 'components/users/UserAvatar';
import { useOwnership } from "hooks/share-hooks";
import PropTypes from "prop-types";

import { EntityTypes } from "types/enums/entity-types";

export const ShareableTargetListItem = ({ target, onClick, selected, disabled = false, ...props }) => {
    console.log(target);
    const isGroup = target.entityType === EntityTypes.GROUP;
    const icon = isGroup
                 ? <GroupAvatar groupId={target.id} />
                 : <UserAvatar userId={target.id} />;
    const tmpOwnership = useOwnership(target.owner);
    const isSharingAllowed = isGroup
                             ? tmpOwnership
                             : true;

    return (
        <ListItem disablePadding {...props}>
            <ListItemButton
                onClick={onClick}
                selected={selected}
                disabled={ !isSharingAllowed || disabled}
            >
                <ListItemAvatar>
                    {icon}
                </ListItemAvatar>
                <ListItemText primary={target.username} />
            </ListItemButton>
        </ListItem>
    );
};

ShareableTargetListItem.propTypes = {
    target: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
}
