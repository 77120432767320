import { EntityTypes } from "types/enums/entity-types";

export default class UserRelationEvent {
    id = '';
    createdAt = '';
    updatedAt = '';
    ownerId = '';
    targetId = '';
    eventType = '';
    eventState = '';

    entityType = EntityTypes.USER_RELATION_EVENT;
}

export const UserRelationEventTypes = {
    FRIENDSHIP_REQUEST: 'FRIENDSHIP_REQUEST',
}

export const UserRelationEventStates = {
    FRIEND_REQUEST_ACCEPTED: 'FRIEND_REQUEST_ACCEPTED',
    FRIEND_REQUEST_CANCELLED: 'FRIEND_REQUEST_CANCELLED',
    FRIEND_REQUEST_REJECTED: 'FRIEND_REQUEST_REJECTED',
    FRIEND_REQUEST_PENDING: 'FRIEND_REQUEST_PENDING',
}
