import { useController } from "@data-client/react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { ShareLinkResource } from "store/dataclient/share-link-resource";
import { selectEntities } from 'store/selectors/entity-selectors';
import { EntityTypes } from 'types/enums/entity-types';

export function useShareLinks() {
    const restCtrl = useController();
    const shareLinks = useSelector(selectEntities({ entityType: EntityTypes.SHARE_LINK }));

    useEffect(() => {
        if ( !shareLinks)
            void restCtrl.fetch(ShareLinkResource.list);
        // eslint-disable-next-line
    }, []);

    return shareLinks;
}

export function useShareLinkFormatter() {
    const location = `${window.location.origin.toString()}`;

    return (linkId) => `${location}/share-links/accept/${linkId}`;
}
