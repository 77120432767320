import {Endpoint} from "@data-client/endpoint";
import axios from "axios";
import {baseUrl} from "store/backend-config";
import {ApiMessageEntity} from "store/dataclient/api-message-entity";
import {dataclientController} from "store/dataclient-store";

export const shareBaseUrl = `${baseUrl}/shares`;

export const ShareResource = {
    list: new Endpoint(
        ({ id }) => axios.get(`${shareBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'listEntityShares',
        }),
    share: new Endpoint(
        ({ id, shareWith }) => axios.post(`${shareBaseUrl}/${id}/${shareWith}`).then(response => {
            void dataclientController.invalidate(ShareResource.list, { id: id });
            return response.data;
        }),
        {
            name: 'shareEntity',
            schema: ApiMessageEntity,
            sideEffect: true,
        }
    ),
    unshare: new Endpoint(
        ({ id, shareWith }) => axios.delete(`${shareBaseUrl}/${id}/${shareWith}`).then(response => {
            void dataclientController.invalidate(ShareResource.list, { id: id });
            return response.data;
        }),
        {
            name: 'unshareEntity',
            schema: ApiMessageEntity,
            sideEffect: true,
        }
    ),
}
