import { Avatar, Tooltip } from '@mui/material';
import { useGroup } from 'hooks/share-hooks';
import PropTypes from 'prop-types';

const GroupAvatar = ({ groupId, sx, variant = 'avatar', ...props }) => {
    const group = useGroup(groupId);
    return (
        <Tooltip title={group?.title || 'AnonGroup'}>
            <Avatar
                sx={{ width: 32, height: 32, ...sx }}
                {...props}
            >
                {group?.title?.charAt(0) || 'G'}
            </Avatar>
        </Tooltip>
    )
}

GroupAvatar.propTypes = {
    groupId: PropTypes.string.isRequired,
    sx: PropTypes.object,
    variant: PropTypes.string
}

export default GroupAvatar;