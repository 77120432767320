import {ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {forwardRef} from "react";
import {Link} from "react-router-dom";

const NavigationMenuListItem = ({ title, icon, onClick, url, ...props }) => {

    const linkComponent = forwardRef((props, ref) => <Link ref={ref} {...props} to={url} target='_self' />);

    return (
        <ListItemButton
            onClick={onClick}
            component={linkComponent}
            {...props}
        >
            {icon &&
                <ListItemIcon sx={{ minWidth: 'auto', marginRight: '10px'}}>
                {icon}
            </ListItemIcon>
            }
            <ListItemText>
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    {title}
                </Typography>
            </ListItemText>
        </ListItemButton>
    )
}

NavigationMenuListItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    url: PropTypes.string,
    onClick: PropTypes.func,
}

export default NavigationMenuListItem;
