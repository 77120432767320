import { useController, useSuspense } from "@data-client/react";
import { useCurrentUser } from "hooks/auth-hooks";
import { useUsers } from "hooks/user-hooks";
import { useCallback } from "react";
import { GroupResource } from "store/dataclient/group-resource";
import { ShareResource } from "store/dataclient/share-resource";
import { useStarList } from "./star-hooks";

export const useGroup = (groupId) => {
    return useSuspense(GroupResource.get, { id: groupId });
}

export const useGroups = () => {
    return useSuspense(GroupResource.list);
}

export const useStarredGroups = () => {
    const starList = useStarList();
    return useGroups().filter(group => starList?.includes(group.id));
}

export const useShareableTargets = () => {
    const groups = useGroups();
    const users = useUsers();
    const currentUser = useCurrentUser();
    const userId = currentUser?.id;
    const ownershipChecker = useOwnershipChecker();

    const out = [
        ...(groups.filter(group => ownershipChecker(group.owner))),
        ...(users.filter(user => user.id !== userId))
    ];
    out.sort((a, b) => a.username.localeCompare(b.username));
    return out;
}

export const useGroupCreationCallback = () => {
    const restCtrl = useController();

    const callback = useCallback((group) => {
        return restCtrl.fetch(GroupResource.create, { group: group });
    }, [restCtrl])

    return callback;
}

export const useShares = (id) => {
    return useSuspense(ShareResource.list, { id: id });
}

export const useShareTriggers = (entityId) => {
    const restCtrl = useController();

    const shareCallback = useCallback((shareWith) => {
        return restCtrl.fetch(ShareResource.share, { id: entityId, shareWith: shareWith });
    }, [restCtrl, entityId])

    const unshareCallback = useCallback((shareWith) => {
        return restCtrl.fetch(ShareResource.unshare, { id: entityId, shareWith: shareWith });
    }, [restCtrl, entityId])

    return [shareCallback, unshareCallback];
}

export const useOwnership = (ownerId) => {
    const currentUser = useCurrentUser();
    return ownerId === currentUser?.id;
}

export const useOwnershipChecker = () => {
    const currentUser = useCurrentUser();
    return useCallback((ownerId) => {
        return ownerId === currentUser.id;
    }, [currentUser]);
}
