import { useSuspense } from '@data-client/react';
import { StatisticsResource } from 'store/dataclient/statistics-resource';

/**
 *
 * @param {string} startDate YYYY-MM-dd
 * @param {string} endDate YYYY-MM-dd
 * @param {string[]=null} parents array of entities to search for
 * @param {string[]=null} owners array of session owners
 * @returns {StatisticsRecord[]}
 * @example
 * // return structure
 * [
 *     //...
 *     {
 *        "date": "2024-01-29",
 *        "duration": 340,
 *        "sessionCount": 4,
 *        "userDetails": [
 *            {
 *                "date": "2024-01-29",
 *                "ownerId": "ce328e77-4091-450c-a08c-e9ccb17806d3",
 *                "duration": 73,
 *                "sessionCount": 1
 *            },
 *            {
 *                "date": "2024-01-29",
 *                "ownerId": "28d8c378-8e8e-48bf-b089-892c0f8179e1",
 *                "duration": 266,
 *                "sessionCount": 3
 *            }
 *        ]
 *    },
 *    //...
 * ]
 */
export function useStatistics({ startDate, endDate, parents = null, owners = null }) {
    const filter = {
        startDate: startDate,
        endDate: endDate,
    }
    if (parents)
        filter.parents = parents;
    if (owners)
        filter.owners = owners;

    return useSuspense(StatisticsResource.search, { filter: filter });
}