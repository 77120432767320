import { Entity } from "@data-client/rest";
import { EntityTypes } from "types/enums/entity-types";


export class GroupEntity extends Entity {
    id = '';
    createdAt = '';
    owner = '';
    title = '';
    entityType = EntityTypes.GROUP;

    static key = EntityTypes.GROUP;

    pk() {
        return this.id;
    }

    get displayName() {
        return this.title;
    }

}
