import { useLive, useSuspense } from '@data-client/react';
import { memo } from 'react';
import { HanaEntityResource } from 'store/dataclient/hana-entity-resource';
import { StarResource } from 'store/dataclient/star-resource';
import { UserEffortResource } from 'store/dataclient/user-effort-resource';

const EntityListSubscriber = () => {
    void useLive(HanaEntityResource.list);
    void useSuspense(StarResource.list);
    void useLive(UserEffortResource.list);

    return (
        <></>
    )
}

export default memo(EntityListSubscriber);