import { useUsers } from 'hooks/user-hooks';
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LineChart } from "@mui/x-charts/LineChart";
import { Select, MenuItem, Stack } from "@mui/material";
import { useStatistics } from 'hooks/statistics-hooks';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekdayPlugin from "dayjs/plugin/weekday";

dayjs.extend(utc);
dayjs.extend(weekdayPlugin);

dayjs().$locale().weekStart = 1;

const shortcutsItems = [
    {
        label: "This week",
        getValue: () => {
            const today = dayjs();
            return [today.startOf("week"), today.endOf("week")];
        }
    },
    {
        label: "Last week",
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, "week").startOf("week"), today.subtract(1, "week").endOf("week")];
        }
    },
    {
        label: "Current Month",
        getValue: () => {
            const today = dayjs();
            return [today.startOf("month"), today.endOf("month")];
        }
    },
    {
        label: "Last Month",
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, "month").startOf("month"), today.subtract(1, "month").endOf("month")];
        }
    },
    {
        label: "Last 3 Months",
        getValue: () => {
            const today = dayjs();
            return [today.subtract(3, "month").startOf("month"), today];
        }
    }
];

const getDatesInRange = (start, end) => {
    const dates = [];
    let current = start;
    while (current <= end) {
        dates.push(dayjs(current).toDate());
        current = dayjs(current).add(1, "day");
    }
    return dates;
};

const getDataForDateInterval = (dates, userData) => {
    return dates.map((date) => {
        const matchingData = userData.filter((dataPoint) => dayjs.utc(dataPoint.x).isSame(date, "day"))[0];
        return matchingData ? matchingData.y : null;
    });
};

const LineChartWidget = ({ projectId, projectUsers }) => {
    const [selectedDateInterval, setSelectedDateInterval] = useState([dayjs().startOf("week"), dayjs().endOf("week")]);
    const [userSessionsMap, setUserSessionsMap] = useState({});
    const usersInfo = useUsers(projectUsers);

    const stats = useStatistics({
        startDate: selectedDateInterval[0].toISOString(),
        endDate: selectedDateInterval[1].toISOString(),
        parents: [projectId],
        owners: projectUsers,
    });

    useEffect(() => {
        let sessionsMap = {};
        stats.forEach(stat => {
            stat.userDetails?.forEach(userDetail => {
                if (userDetail.sessionCount > 0) {
                    if ( !sessionsMap[userDetail.ownerId]) {
                        sessionsMap[userDetail.ownerId] = [];
                    }
                    sessionsMap[userDetail.ownerId].push({
                        x: dayjs(stat.date).toDate(),
                        y: Math.round(userDetail.duration / 60), // Convert seconds to rounded minutes
                        sessionCount: userDetail.sessionCount,
                    });
                }
            });
        });
        setUserSessionsMap(sessionsMap);
    }, [stats]);

    const handleShortcutChange = (event) => {
        const selectedIndex = event.target.value;
        const selectedShortcut = shortcutsItems[selectedIndex];
        const [startDate, endDate] = selectedShortcut.getValue();
        setSelectedDateInterval([startDate, endDate]);
    };

    const datesInRange = getDatesInRange(selectedDateInterval[0], selectedDateInterval[1]);

    const series = projectUsers.map(userId => {
        const userData = userSessionsMap[userId] || [];
        return {
            label: usersInfo?.find(user => user.id === userId)?.username || userId,
            data: getDataForDateInterval(datesInRange, userData)
        };
    });

    return (
        <>
            <Stack direction='row' spacing={1}>
                <Select onChange={handleShortcutChange} size='small' defaultValue={0} sx={{ height: "100%" }}>
                    {shortcutsItems.map((shortcut, index) => (
                        <MenuItem key={index} value={index}>
                            {shortcut.label}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>

            <LineChart
                xAxis={[{
                    id: "Days",
                    data: datesInRange,
                    scaleType: "time",
                    valueFormatter: (tickItem) => {
                        return dayjs(tickItem).format("D MMM");
                    }
                }]}
                series={series}
                height={300}
            />
        </>
    );
};

LineChartWidget.propTypes = {
    projectId: PropTypes.string.isRequired,
    projectUsers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LineChartWidget;
