import { useController } from "@data-client/react";
import { useCurrentUser } from "hooks/auth-hooks";
import { useDeepCompareMemoize } from "hooks/deep-compare-hooks";
import { useEntity } from "hooks/entity-hooks";
import { useRunningTimerSessions } from "hooks/timer-session-hooks";
import { useCallback } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { TimerResource } from "store/dataclient/timer-resource";
import { TimerSessionResource } from "store/dataclient/timer-session-resource";
import { selectEntities, selectEntity } from 'store/selectors/entity-selectors';
import { EntityTypes } from "types/enums/entity-types";
import { computeTimerValue } from "utils/timer-value-helpers";
import { useStarList } from "./star-hooks";

export const useTimers = () => {
    return useSelector(selectEntities({ entityType: EntityTypes.TIMER }), shallowEqual);
}

export const useTimer = (timerId) => {
    return useSelector(selectEntity(timerId), shallowEqual);
}

export const useStarredTimers = () => {
    const starList = useStarList();
    return useTimers().filter(timer => starList.includes(timer.id));
}

const useTimerRunningState = (timerId, userId = null) => {
    const sessions = useRunningTimerSessions(timerId);
    if ( !sessions.length)
        return false;
    if (userId) {
        const userSession = sessions.find(session => session.ownerId === userId && session.parentId === timerId);
        return Boolean(userSession);
    }
    return true;
}

export const useEntityTimer = (entityId) => {
    const restCtrl = useController();
    const { id: userId } = useCurrentUser();
    const entity = useEntity(entityId);

    const runningSessions = useRunningTimerSessions(entityId);

    // const timerValue = useTimerTickingValue(entityId);
    const timerValue = computeTimerValue(entity, runningSessions);
    const isTimerRunning = useTimerRunningState(entityId);
    const isTimerStarted = useTimerRunningState(entityId, userId);

    const runTimerControl = useCallback(() => {
        return restCtrl.fetch(TimerResource.run, { id: entityId });
        // eslint-disable-next-line
    }, [entityId]);

    const pauseTimerControl = useCallback(() => {
        // runningSessions.forEach(session => void restCtrl.fetch(TimerSessionResource.removeFromStore, { id: session.id }));
        const ownSession = runningSessions.find(session => session.parentId === entityId && session.ownerId === userId);
        return restCtrl.fetch(TimerResource.pause, { id: entityId })
                       .then(
                           (res) => new Promise((resolve) => {
                               // if (ownSession && ownSession.duration < 60)
                               void restCtrl.fetch(TimerSessionResource.removeFromStore, ownSession ? { id: ownSession.id } : null);
                               resolve(res);
                           }),
                       );
        // eslint-disable-next-line
    }, useDeepCompareMemoize([entityId, runningSessions.map(session => session.id), userId]));

    return {
        timerValue: timerValue,
        timerIsStarted: isTimerStarted,
        timerIsRunning: isTimerRunning,
        timerRun: runTimerControl,
        timerPause: pauseTimerControl,
    }
}
