import {Endpoint} from "@data-client/endpoint";
import axios from "axios";
import {baseUrl} from "store/backend-config";

export const starBaseUrl = `${baseUrl}/stars`

export const StarResource = {
    list: new Endpoint(
        () => axios.get(`${starBaseUrl}`).then(response => response.data),
        {
            name: 'listStars'
        }
    ),
    star: new Endpoint(
        ({ id }) => axios.post(`${starBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'addStar',
            sideEffect: true,
            update: (id) => ({
                [StarResource.list.key()]: (stars) => [id, ...stars]
            })
        }
    ),
    unstar: new Endpoint(
        ({ id }) => axios.delete(`${starBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'removeStar',
            sideEffect: true,
            update: (id) => ({
                [StarResource.list.key()]: (stars) => stars.filter(entry => entry !== id)
            })
        }
    ),
}
