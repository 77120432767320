import ImageUploadButtonNew from "components/common/ImageUploadButtonNew";
import UserAvatar from "components/users/UserAvatar";
import { useCurrentUser, useUpdateProfileCallback } from "hooks/auth-hooks";
import { memo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box, Button,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Paper,
    Popper,
    Stack,
    Typography,
    Badge,
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { FileInfoKinds } from "types/file-info";
import ProfileMenu from './ProfileMenu';
import ModeIcon from '@mui/icons-material/Mode';


// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();

    const user = useCurrentUser();
    const isLoggedIn = Boolean(user);
    const profileUpdater = useUpdateProfileCallback();

    const anchorRef = useRef(null);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const handleAvatarUpload = (fileInfo) => {
        if (fileInfo?.thumbPath)
            void profileUpdater({ image: fileInfo.thumbPath });
    }

    const handleMenuItemSelect = () => {
        setProfileMenuOpen(false);
    };

    const handleProfileMenuToggle = () => {
        setProfileMenuOpen((prevOpen) => !prevOpen);
    };

    const handleProfileMenuClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setProfileMenuOpen(false);
    };

    if ( !isLoggedIn) {
        return (
            <Button component={RouterLink} to='/login'>Log in</Button>
        )
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' },
                }}
                aria-label='open profile'
                ref={anchorRef}
                aria-controls={profileMenuOpen ? 'profile-grow' : undefined}
                aria-haspopup='true'
                onClick={handleProfileMenuToggle}
            >
                <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
                    <UserAvatar userId={user.id} />
                    <Typography variant='subtitle1'
                                sx={{ display: { xs: 'none', sm: 'block' } }}>{user?.username}</Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement='bottom-end'
                open={profileMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type='fade' in={profileMenuOpen} {...TransitionProps}>
                        {profileMenuOpen && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    border: 1,
                                    borderColor: theme.palette.secondary.lighter,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250,
                                    },
                                }}
                            >
                                <ClickAwayListener onClickAway={handleProfileMenuClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                                            <Stack direction='column' alignItems='center'>
                                                <Badge
                                                    overlap='circular'
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    badgeContent={
                                                        <ImageUploadButtonNew
                                                            sx={{
                                                                p: 0.1,
                                                                bgcolor: 'transparent',
                                                                borderRadius: 10,
                                                                '&:hover': { bgcolor: 'secondary.light' },
                                                            }}
                                                            aria-label='open profile'

                                                            parentId={user?.id}
                                                            fileKind={FileInfoKinds.USER_AVATAR}
                                                            onUpload={handleAvatarUpload}
                                                            isAvatar
                                                        >
                                                            <Avatar alt='Upload Photo' sx={{
                                                                width: 22,
                                                                height: 22,
                                                                bgcolor: theme.palette.icon.main,
                                                                border: `2px solid ${theme.palette.background.paper}`,
                                                            }}>
                                                                <ModeIcon sx={{ height: 15 }} />
                                                            </Avatar>
                                                        </ImageUploadButtonNew>
                                                    }
                                                >
                                                    <UserAvatar
                                                        userId={user.id}
                                                        sx={{
                                                            width: 60,
                                                            height: 60,
                                                        }}
                                                    />
                                                </Badge>
                                                <Typography variant='h6'>{`${user?.username}`}</Typography>
                                                <Typography variant='body2'
                                                            color='textSecondary'
                                                >
                                                    {user?.email}
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />
                                        <ProfileMenu onMenuItemSelect={handleMenuItemSelect} />
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default memo(Profile);
