import { Endpoint, schema } from "@data-client/endpoint";
import axios from "axios";

import { baseUrl } from "store/backend-config";
import { HanaEntityResource } from "store/dataclient/hana-entity-resource";
import { timerBaseUrl } from "store/dataclient/timer-resource";
import { TimerSessionEntity } from "store/dataclient/timer-session-entity";
import { dataclientController as restCtrl } from "store/dataclient-store";
import { UserEffortResource } from "store/dataclient/user-effort-resource";

const sessionBaseUrl = `${baseUrl}/timers/sessions`;


export const TimerSessionResource = {
    get: new Endpoint(
        ({ id }) => axios.get(`${sessionBaseUrl}/${id}`).then(resp => resp.data),
        {
            name: 'timerSessionGet',
            schema: TimerSessionEntity,
        }
    ),
    listRunning: new Endpoint(
        () => axios.get(`${sessionBaseUrl}/running`).then(resp => {
            return resp.data
        }),
        {
            name: 'timerSessionListRunning',
            schema: [TimerSessionEntity],
            pollFrequency: 30 * 1000,
        }
    ),
    list: new Endpoint(
        ({ entityId }) => axios.get(`${timerBaseUrl}/${entityId}/sessions`).then(resp => resp.data),
        {
            name: 'timerSessionListEntity',
            schema: [TimerSessionEntity],
            pollFrequency: 60 * 1000,
        }
    ),
    patch: new Endpoint(
        ({ session }) => axios.patch(`${sessionBaseUrl}/${session.id}`, session).then(resp => resp.data),
        {
            name: 'timerSessionPatch',
            schema: TimerSessionEntity,
            sideEffect: true,
        }
    ),
    delete: new Endpoint(
        ({ id }) => axios.delete(`${sessionBaseUrl}/${id}`).then(resp => {
            // if (resp?.data?.status === 'OK')
            //     void restCtrl.fetch(TimerSessionResource.removeFromStore, [{ id: id }]);
            // refresh entities durations
            void restCtrl.expireAll(HanaEntityResource.list);
            void restCtrl.expireAll(UserEffortResource.list);
            return resp.data;
        }),
        {
            name: 'timerSessionDelete',
            sideEffect: true,
            schema: TimerSessionEntity,
        }
    ),
    removeFromStore: new Endpoint(
        (entity) => new Promise((resolve, _reject) => resolve(entity)),
        {
            name: 'timerSessionStoreRemove',
            sideEffect: true,
            schema: new schema.Invalidate(TimerSessionEntity),
        }
    ),
    putIntoStore: new Endpoint(
        ({ sessions }) => new Promise((resolve) => resolve(sessions)),
        {
            name: 'timerSessionStorePut',
            sideEffect: true,
            schema: [TimerSessionEntity],
        }
    )
}
