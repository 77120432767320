import SessionsList from "components/common/SessionsList";
import { useEntityControls } from "hooks/entity-hooks";
import { useEntityTimerSessions, usePaginatedSessions } from "hooks/timer-session-hooks";
import React, { useState } from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';
import EntityFieldTextInput from "components/common/EntityFieldTextInput";
import { useTask } from 'hooks/task-hooks';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button, Box, Grid, Paper, Typography, AppBar, Tabs, Tab } from '@mui/material';
import TaskList from "components/tasks/TaskList";
import { useProjectTasks } from "hooks/task-hooks";
import { useMobileDetector } from 'hooks/theme-hooks';
import CreationButton from "components/common/CreationButton";
import { EntityTypes } from "types/enums/entity-types";
import { useEntity } from "hooks/entity-hooks";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { bottomNavigationHeight } from "config";
import LineChartWidget from "components/charts/LineChartWidget";
import { useActiveEntityUsers } from "hooks/user-effort-hooks";

export const TaskPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { taskId } = useParams();
    const task = useTask(taskId);
    const taskUsers = useActiveEntityUsers(task.id);
    const parent = useEntity(task?.parentId);
    const mobileDevice = useMobileDetector();
    const { entityUpdate: taskUpdateCallback } = useEntityControls(taskId);
    // const authorUser = useUser(task.creator);
    const tasks = useProjectTasks(taskId);
    const [value, setValue] = useState(0);
    const parentLink = parent.entityType === EntityTypes.PROJECT ? `/projects/${task.parentId}` : `/tasks/${task.parentId}`;

    const [showDescriptionInput, setShowDescriptionInput] = useState(Boolean(task.description));
    const [isEditingDescription, setIsEditingDescription] = useState(false);

    const allSessions = useEntityTimerSessions(taskId);
    const { currentSessions, loadMoreSessions, hasMore } = usePaginatedSessions(allSessions);

    const handleAddDescriptionClick = () => {
        setShowDescriptionInput(true);
        setIsEditingDescription(true);
    };

    const handleDescriptionCommit = (newValue) => {
        taskUpdateCallback({ description: newValue });
        setShowDescriptionInput(!!newValue); // Conditionally hide the input if newValue is empty
        setIsEditingDescription(false); // Reset editing state
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Button variant='text' component={RouterLink} to={parentLink}>
                {/* {t('to_the_project_page')} */}
                <KeyboardBackspaceIcon /> {parent?.title || t('unknown')}
            </Button>
            <Box display='flex' alignItems='flex-start' flexDirection='column' sx={{ pt: 1, pb: 3 }}>
                <EntityFieldTextInput
                    variant='plain'
                    fontStyles={{ fontSize: '2em', pb: 1 }}
                    value={task.title}
                    onCommit={newValue => taskUpdateCallback({ title: newValue })}
                />
                {showDescriptionInput ? (
                    <EntityFieldTextInput
                        variant='plain'
                        fontStyles={{ fontSize: '1.2em', lineHeight: '1.3em', color: theme.palette.text.secondary }}
                        value={task.description ? task.description : ''}
                        onCommit={handleDescriptionCommit}
                        isEditing={isEditingDescription} // Pass the isEditing state to the component
                    />
                ) : (
                    <Button onClick={handleAddDescriptionClick}>{t('add_description')}</Button>
                )}
            </Box>
            <CreationButton entityId={taskId} entityType='task' />
            {/* {authorUser ? authorUser.name : t('unknown')} {t('at')} {task?.createdAt} */}

            {!mobileDevice ? (
                <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item md={8}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Typography variant="h5" sx={{ p: 1 }}>
                                {t('tasks')}
                            </Typography>
                            <TaskList tasks={tasks} projectId={taskId} />
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper elevation={0} sx={{ pt: 2 }}>
                            <Box sx={{ pl: 1 }}>
                                <LineChartWidget
                                    projectId={taskId}
                                    projectUsers={taskUsers}
                                />
                            </Box>
                            <Typography variant="h5" sx={{ p: 1 }}>
                                {t('sessions')}
                            </Typography>
                            <SessionsList
                                sessions={currentSessions}
                                fetchMoreSessions={loadMoreSessions}
                                hasMoreSessions={hasMore}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" color="default" elevation={1}
                        sx={{
                            position: 'fixed',
                            bottom: bottomNavigationHeight,
                            borderTop: `1px solid ${theme.palette.divider}`,
                            zIndex: 1100,
                            height: 40,
                            mt: 0

                        }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            sx={{
                                minHeight: '40px',
                                '& .MuiTab-root': {
                                    minHeight: '40px',
                                    padding: '6px 0',
                                },
                            }}
                        >
                            <Tab label={t('tasks')} />
                            <Tab label={t('sessions')} />
                        </Tabs>
                    </AppBar>
                    {value === 0 && <TaskList tasks={tasks} projectId={taskId} />}
                    {value === 1 && (
                        <>                  <Box sx={{ pt: 1, pl: 1 }}>
                            <LineChartWidget
                                projectId={taskId}
                                projectUsers={taskUsers}
                            />
                        </Box>
                            <SessionsList
                                sessions={currentSessions}
                                fetchMoreSessions={loadMoreSessions}
                                hasMoreSessions={hasMore}
                            />
                        </>
                    )}
                </Box>
            )}
        </>
    );
};

export default TaskPage;
