import { useDLE, useSuspense } from "@data-client/react";
// import useEndpointMeta from 'hooks/dataclient/useEndpointMeta';
import { useMemo } from "react";
// import { shallowEqual, useSelector } from "react-redux";
import { UserResource } from "store/dataclient/user-entity";
// import { selectUser, selectUsers } from "store/selectors/entity-selectors";

export const useUsers = (userIdList) => {
    const users = useSuspense(UserResource.list);
    // const restCtrl = useController();
    // const users = useSelector(selectUsers(), shallowEqual);
    // const meta = useEndpointMeta(UserResource.list);
    //
    if (users) {
        if (userIdList?.length > 0) {
            return users.filter(user => userIdList.includes(user.id));
        }
        return users;
    }
    //
    // if ( !meta?.error) {
    //     throw restCtrl.fetch(UserResource.list);
    // }
};

export const useUser = (userId) => {
    // const restCtrl = useController();
    // const user = useSelector(selectUser(userId));
    // const meta = useEndpointMeta(UserResource.list);

    // if ( !userId)
    //     return null;

    // if (user)
    //     return user;
    // if ( !meta?.error) {
    //     throw restCtrl.fetch(UserResource.list);
    // }

    return useSuspense(UserResource.get, { id: userId });
};

/**
 * Searches users by the part of username, suspends
 * @param {string} namePart string to search for
 * @returns {UserEntity[]} array of found users or empty array
 */
export function useUserSearch(namePart) {
    const searchString = namePart?.trim();
    const { data: foundUsers } = useDLE(UserResource.search, searchString?.length > 2 ? { search: searchString } : null);

    return useMemo(
        () => foundUsers ? foundUsers : []
        // eslint-disable-next-line
        , [foundUsers]);
}