import { EntityTypes } from "types/enums/entity-types";

export default class TimerSession {
    id = '';
    createdAt = '';
    ownerId = '';
    parentId = '';

    startTime = '';
    endTime = null;
    duration = null;

    description = '';
    archived = null;
    discarded = null;

    entityType = EntityTypes.TIMER_SESSION;
}
