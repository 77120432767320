import React from "react";
import { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import LightThemeOption from './light';
import DarkThemeOption from './dark';
import { DisplayModes } from "store/reducers/slice-theme";
import defaultColors from './default-colors'

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
    const colors = defaultColors;
    const paletteColor = (mode === DisplayModes.LIGHT ? LightThemeOption(colors) : DarkThemeOption(colors))

    const darkPalette = useMemo(
        () => ({
            common: {
                black: '#000',
                white: '#fff'
            },
            ...paletteColor,
            text: {
                primary: "rgba(255, 255, 255, 0.92)",
                secondary: paletteColor.grey[400],
                info: paletteColor.info.main,
                disabled: paletteColor.grey[600]
            },
            action: {
                disabled: paletteColor.grey[700]
            },
            icon: {
                main: paletteColor.grey[100],
            },
            divider: paletteColor.grey[700],
            background: {
                paper: paletteColor.grey[900],
                default: "rgba(0, 0, 0, 1)"
            }
        }),
        [paletteColor]
    );

    const lightPalette = useMemo(
        () => ({
            common: {
                black: '#000',
                white: '#fff'
            },
            ...paletteColor,
            text: {
                primary: paletteColor.grey[900],
                secondary: paletteColor.grey[500],
                info: paletteColor.info.main,
                disabled: paletteColor.grey[400]
            },
            icon: {
                main: paletteColor.grey[600],
            },
            action: {
                disabled: paletteColor.grey[300]
            },
            divider: paletteColor.grey[200],
            background: {
                paper: "rgba(255, 255, 255, 0.95)",
                default: '#fff'
            }
        }),
        [paletteColor]
    );

    return React.useMemo(() => createTheme({
        palette: {
            mode,
            ...(mode === DisplayModes.LIGHT ? lightPalette : darkPalette)
        }
    }), [mode, lightPalette, darkPalette]);

};

export default Palette;