export default class HanaEntity {
    id = '';
    createdAt = '';
    updatedAt = '';
    ownerId = '';
    parentId = null;

    title = '';
    description = null;
    state = null;
    archived = false;
    discarded = false;
    knownDuration = 0;

    children = [];
    timerSessions = [];
}
