import { useController } from "@data-client/react";
import { Dialog, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ClipboardCopyButton from "components/common/ClipboardCopyButton";
import { useShareLinkFormatter } from "hooks/share-link-hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import { ShareLinkResource } from "store/dataclient/share-link-resource";
import { ShareLinkShareType } from "types/share-link";

const ShareLinkCreationDialog = ({ open, onClose, parentId, ...props }) => {
    const restCtrl = useController();
    const [targetUserHandle, setTargetUserHandle] = useState('');

    const [link, setLink] = useState(null);
    const linkFormatter = useShareLinkFormatter();

    function handleMainDialogClose() {
        setTargetUserHandle('');
        onClose();
    }

    function handleCreateLink() {
        const shareLink = {
            shareType: ShareLinkShareType.ENTITY,
            targetId: parentId,
        };
        restCtrl.fetch(ShareLinkResource.create, { shareLink: shareLink })
            .then(result => {
                handleMainDialogClose();
                setLink(result);
            });
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleMainDialogClose}
                fullWidth
                maxWidth='sm'
                {...props}
            >
                <DialogTitle>create_share_link</DialogTitle>
                <DialogContent>
                    <Stack direction='column' spacing={2}>
                        <DialogContentText>target_user_handle</DialogContentText>
                        <TextField
                            autoFocus
                            id="user"
                            label="user"
                            fullWidth
                            value={targetUserHandle}
                            onChange={(changeEvent) => {
                                setTargetUserHandle(changeEvent.target.value)
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMainDialogClose} variant='outlined'>cancel</Button>
                    <Button onClick={handleCreateLink} variant='contained'>create</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={Boolean(link)}
                onClose={() => setLink(null)}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle>share_link_created</DialogTitle>
                <DialogContent>
                    <TextField
                        label="share_link"
                        fullWidth
                        defaultValue={linkFormatter(link?.id)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <ClipboardCopyButton text={linkFormatter(link?.id)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLink(null)} variant='outlined'>close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

ShareLinkCreationDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    parentId: PropTypes.string,
}

export default ShareLinkCreationDialog;
