import { useEntity, useEntityCreationCallback, useEntityList } from "hooks/entity-hooks";
import { EntityTypes } from "types/enums/entity-types";
import { useStarList } from './star-hooks';

export const useTasks = () => {
    return useEntityList({ entityType: EntityTypes.TASK });
}

export const useTask = (taskId) => {
    return useEntity(taskId);
};

export const useProjectTasks = (projectId) => {
    return useTasks().filter(task => task.parentId === projectId);
}

export const useStarredTasks = () => {
    const starList = useStarList();
    const tasks = useTasks();
    return tasks.filter(task => starList.includes(task.id));
};

export const useTaskCreationCallback = (parentId) => {
    return useEntityCreationCallback({ entityType: EntityTypes.TASK, parentId: parentId });
}
