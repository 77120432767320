import { createSelector } from "@reduxjs/toolkit";
import { EntityTypes } from "types/enums/entity-types";

import { filesBaseUrl } from "store/backend-config";
import { comparatorCreatedAtDesc } from "utils/comparators";

const ENTITY = EntityTypes.ENTITY;
const USER = EntityTypes.USER;
const TIMER_SESSION = EntityTypes.TIMER_SESSION;

const selectDataclient = (state) => state.dataclient.entities;
const selectAll = (state, category) => Object.values(state?.[category] ? state[category] : []).sort(comparatorCreatedAtDesc);

// ENTITY type (timers, projects, etc)
export const selectEntities = ({ archived = false, entityType = null, parentId = null } = {}) => createSelector(selectDataclient,
    state => selectAll(state, ENTITY)
        .filter(entry =>
            !entry.discarded
            && (Boolean(entry.archived) === archived)
            && ( !entityType || entry.entityType === entityType)
            && ( !parentId || entry.parentId === parentId)
        )
        .sort(comparatorCreatedAtDesc)
);
export const selectEntity = (id) => createSelector(selectDataclient,
    state => state[ENTITY]?.[id]);

const selectChildIds = (state, parents = [], archived = false) => {
    return parents?.length
           ? state.filter(entry => parents.includes(entry.parentId) && (Boolean(entry.archived) === archived)).map(entry => entry.id)
           : [];
};

export const selectChildIdsRecursive = (state, parents = [], archived = false) => {
    if ( !parents?.length)
        return [];
    const childs = selectChildIds(state, parents, archived);
    return childs?.length
           ? [...childs, ...selectChildIdsRecursive(state, childs, archived)]
           : [];
};

export const selectChildsRecursive = ({ parents = [] } = {}) => createSelector(
    selectDataclient,
    state => {
        const entityState = selectAll(state, ENTITY);
        const childs = selectChildIdsRecursive(entityState, parents);
        return childs?.length
               ? entityState.filter(entry => childs.includes(entry.id))
               : [];
    }
);

// USER type
const fillAvatarUrl = (user) => {
    if (user?.image)
        user.avatarUrl = `${filesBaseUrl}/${user.image}`;
    return user;
};
export const selectUser = (id) => createSelector(selectDataclient,
    state => fillAvatarUrl(state[USER]?.[id]));
export const selectUsers = () => createSelector(selectDataclient,
    state => {
        const users = selectAll(state, USER);
        if (users)
            users.forEach(user => fillAvatarUrl(user));
        return users;
    }
);

// TIMER_SESSION type
export const selectTimerSessions = ({ archived = false, parents = [], ownerId = null } = {}) => createSelector(selectDataclient,
    state => {
        const sessions = selectAll(state, EntityTypes.TIMER_SESSION);
        return sessions.filter(entry => {
                return ( !archived || entry.archived)
                    && !entry.discarded
                    && ( !parents?.length || parents.includes(entry.parentId))
                    && ( !ownerId || entry.ownerId === ownerId)
            }
        )
    }
);

export const selectTimerSessionsRunning = ({ parents = [], ownerId = null } = {}) => createSelector(selectDataclient,
    state => {
        const sessions = selectAll(state, EntityTypes.TIMER_SESSION);
        // console.log(`sessions selector for ${parents}`);
        return sessions.filter(entry => {
                return ( !entry.archived)
                    && !entry.discarded
                    && !entry.endTime
                    && ( !parents?.length || parents.includes(entry.parentId))
                    && ( !ownerId || entry.ownerId === ownerId)
            }
        )
    }
);

export const selectTimerSession = (id) => createSelector(selectDataclient,
    state => state[TIMER_SESSION]?.[id]);

// FILE_INFO type
export const selectFiles = ({ parents = [], fileKind = null } = {}) => createSelector(selectDataclient,
    state => selectAll(state, EntityTypes.FILE_INFO)
        .filter(entry =>
            !entry.discarded
            && ( !parents || parents.includes(entry.parentId))
            && ( !fileKind || entry.fileKind === fileKind)
        ));
