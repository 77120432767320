// material-ui

import HanatulLogoIcon from "components/common/HanatulLogoIcon";

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        <>
            <HanatulLogoIcon />
        </>
    );
};

export default Logo;
