import SessionsList from "components/common/SessionsList";
import EntityFieldTextInput from "components/common/EntityFieldTextInput";
import { useTimer } from "hooks/timer-hooks";
import { useParams } from 'react-router-dom';
import { useEntityTimerSessions, usePaginatedSessions } from "hooks/timer-session-hooks";

import { Typography, Box } from '@mui/material';

import baseBackground from 'assets/images/cardlogo.jpg';

import { useOwnership } from "hooks/share-hooks";
import { ShareButton } from "components/shares/ShareButton";
import { useTranslation } from 'react-i18next';
import { useEntityCoverUrl } from "hooks/file-hooks";
import { useEntityControls } from "hooks/entity-hooks";
import { useTheme, alpha } from '@mui/material/styles';
import LineChartWidget from "components/charts/LineChartWidget";
import { useActiveEntityUsers } from "hooks/user-effort-hooks";

export default function TimerPage() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { timerId } = useParams();
    const timer = useTimer(timerId);
    const timerUsers = useActiveEntityUsers(timer.id);
    const isOwnTimer = useOwnership(timer.ownerId);
    const entityCoverUrl = useEntityCoverUrl(timer.id);
    const { entityUpdate: projectUpdateCallback } = useEntityControls(timerId);

    const allSessions = useEntityTimerSessions(timerId);
    const { currentSessions, loadMoreSessions, hasMore } = usePaginatedSessions(allSessions);

    if (timer) {
        return (
            <>
                <Box
                    display='flex'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    sx={{
                        position: 'relative',
                        direction: 'column',
                        width: '100%',
                        height: '250px',
                        p: 2,
                        borderRadius: '6px',
                        backgroundImage:
                            `linear-gradient(to bottom, ${alpha(theme.palette.common.black, 0.4)} 0%,
                        ${alpha(theme.palette.common.black, 0)} 45%),
                        url(${entityCoverUrl || baseBackground})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <EntityFieldTextInput
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.7),
                                borderRadius: "2px",
                                paddingLeft: "0.44em",
                                zIndex: 1
                            }}
                            value={timer.title}
                            onCommit={newValue => projectUpdateCallback({ title: newValue })}
                        />
                        {isOwnTimer && <ShareButton variant='box-card' parentId={timerId} />}
                    </Box>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h4'>{t('sessions')}</Typography>
                    <Box sx={{ pl: 1 }}>
                        <LineChartWidget
                            projectId={timerId}
                            projectUsers={timerUsers}
                        />
                    </Box>
                </Box>
                <SessionsList
                    sessions={currentSessions}
                    fetchMoreSessions={loadMoreSessions}
                    hasMoreSessions={hasMore}
                />
            </>
        )
    } else {
        return (
            <Typography variant='h2'>{t('loading')}</Typography>
        )
    }
}
