import { Endpoint } from "@data-client/endpoint";
import axios from "axios";
import { baseUrl } from "store/backend-config";
import { HanaEntityImpl } from "store/dataclient/hana-entity-impl";

export const entityBaseUrl = `${baseUrl}/entity`;

export const HanaEntityResource = {
    get: new Endpoint(
        ({ id }) => axios.get(`${entityBaseUrl}/${id}`).then(response => response.data),
        {
            name: 'entityGet',
            schema: HanaEntityImpl,
            pollFrequency: 60 * 1000,
        }
    ),
    list: new Endpoint(
        () => axios.get(`${entityBaseUrl}`).then(response => response.data),
        {
            name: 'entityList',
            schema: [HanaEntityImpl],
            pollFrequency: 120 * 1000,
        }
    ),
    create: new Endpoint(
        ({ entity }) => axios.post(`${entityBaseUrl}`, entity).then(response => response.data),
        {
            name: 'entityCreate',
            schema: HanaEntityImpl,
            sideEffect: true,
            update: (newEntity) => ({
                [HanaEntityResource.list.key()]: entities => [newEntity, ...entities]
            })
        }
    ),
    patch: new Endpoint(
        ({ entity }) => axios.patch(`${entityBaseUrl}/${entity.id}`, entity).then(response => response.data),
        {
            name: 'entityPatch',
            schema: HanaEntityImpl,
            sideEffect: true,
        }
    )
}
