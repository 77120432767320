import { useEntity } from 'hooks/entity-hooks';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectEntities } from 'store/selectors/entity-selectors';
import { EntityTypes } from "types/enums/entity-types";
import { useStarList } from './star-hooks';

export const useProjects = () => {
    // const projects = useEntityList().filter(entity => entity.entityType === EntityTypes.PROJECT);
    const projects = useSelector(selectEntities({ entityType: EntityTypes.PROJECT }), shallowEqual);
    // const projects = useCache(projectsQuery);
    return projects ? projects : [];
}

export const useProject = (projectId) => {
    return useEntity(projectId);
}

export const useStarredProjects = () => {
    const starList = useStarList();
    const projects = useProjects();
    return useMemo(() => projects.filter(project => starList.includes(project.id)), [starList, projects]);
};
