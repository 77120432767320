import { useController } from "@data-client/react";
import { dequal } from 'dequal';
import { useState, useEffect } from 'react';
import { useCurrentUser } from "hooks/auth-hooks";
import { useChildEntitiesIdList } from "hooks/entity-hooks";
import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { TimerSessionResource } from "store/dataclient/timer-session-resource";
import { selectTimerSession, selectTimerSessions, selectTimerSessionsRunning } from 'store/selectors/entity-selectors';

export function useEntityTimerSessions(entityId, { archived = false } = {}) {
    // useLive(TimerSessionResource.list, { entityId: entityId });
    const children = useChildEntitiesIdList(entityId);
    const sessionSelector = useMemo(() => selectTimerSessions({ parents: [entityId, ...children], archived: archived })
        , []);

    const sessions = useSelector(sessionSelector, dequal);
    // if (sessions)
    //     sessions.sort(comparatorCreatedAtDesc);

    return sessions;

}

export function usePaginatedSessions(sessions, pageSize = 10) {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentSessions, setCurrentSessions] = useState([]);
    const [loading, setLoading] = useState(false);  // Track loading state for new sessions

    useEffect(() => {
        // Guard clause to handle null or undefined sessions gracefully
        if ( !sessions) {
            setCurrentSessions([]);
            return;
        }

        // Calculate the end index for the slice method based on the current page and pageSize
        const endIndex = (currentPage + 1) * pageSize;
        const newSessions = sessions.slice(0, endIndex);

        setCurrentSessions(newSessions);
    }, [sessions, currentPage, pageSize]);

    const loadMoreSessions = useCallback(() => {
        // Check if already loading or no more sessions to load
        if (loading || (currentPage + 1) * pageSize >= (sessions ? sessions.length : 0)) {
            return Promise.reject('No more data to fetch or already loading');
        }

        setLoading(true); // Set loading state to true
        return new Promise(resolve => {
            setTimeout(() => {
                setCurrentPage(prevPage => prevPage + 1);
                setLoading(false); // Reset loading state
                resolve(); // Resolve the promise after updating the page
            }, 1000); // Simulate delay for fetching data
        });
    }, [currentPage, pageSize, sessions, loading]);

    return {
        currentSessions,
        loadMoreSessions,
        hasMore: (currentPage + 1) * pageSize < (sessions ? sessions.length : 0),
        loading
    };
}

export const useRunningTimerSessions = (parentId = null, userId = null) => {
    let parents = parentId? [parentId] : null;
    const childsIdList = useChildEntitiesIdList(parentId);

    if(parents && childsIdList)
        parents = [...parents, ...childsIdList]

    return useSelector(selectTimerSessionsRunning({
        parents: parents,
        ownerId: userId,
    }), shallowEqual);
}

export function useOwnRunningTimerSession(entityId) {
    const { id: userId } = useCurrentUser();
    const sessions = useRunningTimerSessions(entityId, userId);
    return sessions?.length ? sessions.find(session => session.parentId === entityId) : null;
}

export function useTimerSession(sessionId) {
    // return useSuspense(TimerSessionResource.get, { id: sessionId });
    const sessionSelector = useMemo(() => selectTimerSession(sessionId), [sessionId]);
    return useSelector(sessionSelector);
}

/**
 * Returns callbacks to modify timer session. Callbacks return promises which resolve with updated session object.
 * @param sessionId
 * @returns {{sessionArchive: (function()), sessionDelete: (function()), sessionSetDescription: (function(string))}}
 */
export function useTimerSessionControls(sessionId) {
    const restCtrl = useController();

    const sessionArchive = useCallback(
        () => restCtrl.fetch(TimerSessionResource.patch, { session: { id: sessionId, archived: true } })
        // eslint-disable-next-line
        , [sessionId]);

    const sessionDelete = useCallback(
        () => restCtrl.fetch(TimerSessionResource.delete, { id: sessionId })
        // eslint-disable-next-line
        , [sessionId]);

    const sessionSetDescription = useCallback(
        (description) => restCtrl.fetch(TimerSessionResource.patch, { session: { id: sessionId, description: description } })
        // eslint-disable-next-line
        , [sessionId]);


    return useMemo(() => ({
        sessionArchive,
        sessionDelete,
        sessionSetDescription,
        // eslint-disable-next-line
    }), [sessionId]);
}
