import { useController, useLive } from '@data-client/react';
import { useCallback, useMemo } from 'react';
import { UserResource } from 'store/dataclient/user-entity';
import { UserRelationEventResource } from 'store/dataclient/user-relation-event-resource';

/**
 * Returns an array of the current user's friends
 * @returns {UserEntity[]} array of found users or empty array
 */
export function useUserFriends() {
    const friends = useLive(UserResource.listFriends);
    return friends ? friends : [];
}

/**
 * Returns an array of the current user's friendship requests, both incoming and outcoming
 * @returns {UserRelationEventEntity[]} array of found requests or empty one
 */
export function useFriendshipRequests() {
    const requests = useLive(UserRelationEventResource.list);
    return requests ? requests : [];
}

/**
 * Returns friendship relation state with user
 * @param {string} userId user id to check
 * @returns {boolean} true if the user found in the friends array
 */
export function useFriendshipCheck(userId) {
    const friend = useUserFriends().find(user => user.id === userId);
    const friendId = friend?.id;

    return useMemo(
        () => friendId === userId
        // eslint-disable-next-line
        , [friendId]);
}

/**
 * Returns callbacks to request/cancel friendship
 * @returns {{friendshipRequest: function(userId: string), friendshipCancel: function(userId: string)}} callback params: target user id
 */
export function useFriendshipControls() {
    const restCtrl = useController();
    const friendshipRequest = useCallback(
        (userId) => restCtrl.fetch(UserRelationEventResource.createRequest, { targetId: userId })
        // eslint-disable-next-line
        , []);
    const friendshipCancel = useCallback(
        (userId) => restCtrl.fetch(UserRelationEventResource.cancelFriendship, { targetId: userId })
        // eslint-disable-next-line
        , []);

    return { friendshipRequest, friendshipCancel };
}

/**
 * Returns callbacks to interact with the friendship request
 * @param {string} requestId friendship request id
 * @returns {{requestRejectCallback: function(), requestAcceptCallback: function(), requestCancelCallback: function()}}
 */
export function useFriendshipRequestControls(requestId) {
    const restCtrl = useController();
    const requestAcceptCallback = useCallback(
        () => restCtrl.fetch(UserRelationEventResource.acceptRequest, { id: requestId })
        // eslint-disable-next-line
        , []);
    const requestRejectCallback = useCallback(
        () => restCtrl.fetch(UserRelationEventResource.rejectRequest, { id: requestId })
        // eslint-disable-next-line
        , []);
    const requestCancelCallback = useCallback(
        () => restCtrl.fetch(UserRelationEventResource.cancelRequest, { id: requestId })
        // eslint-disable-next-line
        , []);

    return { requestAcceptCallback, requestRejectCallback, requestCancelCallback };
}