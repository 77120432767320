import { Endpoint } from '@data-client/endpoint';
import axios from 'axios';
import { baseUrl } from 'store/backend-config';

export const statsBaseUrl = `${baseUrl}/statistics`;

export const StatisticsResource = {
    search: new Endpoint(
        ({ filter }) => axios.post(`${statsBaseUrl}/search`, filter).then(resp => resp.data),
        {
            name: 'statsSearch',
        },
    ),
}