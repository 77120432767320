import { useController } from "@data-client/react";
import { useCurrentUser } from "hooks/auth-hooks";
import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { HanaEntityResource } from "store/dataclient/hana-entity-resource";
import { selectEntities, selectChildsRecursive, selectEntity } from "store/selectors/entity-selectors";
import { selectEndpointMeta } from 'store/selectors/dataclient-selectors';

export function useEntityList({ entityType, archived } = { entityType: null, archived: false }) {
    const tmpEntities = useSelector(selectEntities({ entityType, archived }), shallowEqual);
    return tmpEntities ? tmpEntities : [];
}

export function useEntity(entityId) {
    // return useEntityList().find(entity => entity.id === entityId);
    const restCtrl = useController();
    const entity = useSelector(selectEntity(entityId), shallowEqual);
    const meta = useSelector(selectEndpointMeta(HanaEntityResource.get, { id: entityId }));

    if (entity)
        return entity;

    if ( !meta?.error)
        throw restCtrl.fetch(HanaEntityResource.get, { id: entityId });
    // const args = entityId ? { id: entityId } : null;
    // return useSuspenseRedux(HanaEntityResource.get, args);
}

export function useChildEntities(entityId) {
    return useSelector(selectChildsRecursive({ parents: [entityId] }), shallowEqual);
}

export function useChildEntitiesIdList(entityId) {
    return useChildEntities(entityId).map(entity => entity.id);
}

export function useEntityCreationCallback({ entityType = null, parentId = null } = {}) {
    const restCtrl = useController();

    return useCallback(
        (entity) => {
            const newEntity = Object.assign({}, entity);
            if (entityType)
                newEntity.entityType = entityType;
            if (parentId)
                newEntity.parentId = parentId;

            return restCtrl.fetch(HanaEntityResource.create, { entity: newEntity });

            // eslint-disable-next-line
        }, [entityType, parentId]);
}

export function useEntityControls(entityId) {
    const restCtrl = useController();

    const entityUpdate = (entity) => {
        entity.id = entityId;
        return restCtrl.fetch(HanaEntityResource.patch, { entity: entity });
    };

    const entityArchive = () => entityUpdate({ archived: true });

    const entityDelete = () => entityUpdate({ discarded: true });

    return useMemo(
        () => ({ entityUpdate, entityArchive, entityDelete })
        // eslint-disable-next-line
        , [entityId]);
}

export function useEntityOwnership(entityId) {
    const { ownerId } = useEntity(entityId);
    const { id: userId } = useCurrentUser();
    return ownerId === userId;
}
