import axios from 'axios';
import { Endpoint } from '@data-client/endpoint';

import { EntityTypes } from 'types/enums/entity-types';

import { baseUrl } from '../backend-config';
// import { filesBaseUrl } from 'store/backend-config';
import { Entity } from '@data-client/rest';

const baseUrlUser = `${baseUrl}/users`
const baseUrlFriends = `${baseUrl}/friends`


export class UserEntity extends Entity {
    id = '';
    email = '';
    name = '';
    surname = '';
    roles = [];
    accessToken = '';
    username = '';
    image = '';
    entityType = EntityTypes.USER;

    static key = EntityTypes.USER;

    pk() {
        return this.id;
    }

    get displayName() {
        return this.username;
    }

    // get avatarUrl() {
    //     if (this.image) {
    //         return `${filesBaseUrl}/${this.image}`;
    //     }
    //     return null;
    // }
}

const getUser = ({ id }) =>
    axios.get(`${baseUrlUser}/${id}`).then(response => response.data);

export const UserResource = {
    get: new Endpoint(getUser, {
        name: 'usersGet',
        schema: UserEntity,
    }),
    list: new Endpoint(
        () => axios.get(`${baseUrlUser}`).then(response => response.data),
        {
            name: 'usersList',
            schema: [UserEntity],
        },
    ),
    search: new Endpoint(
        ({ search }) => axios.get(`${baseUrlUser}/search`, { params: { q: search } }).then(resp => resp.data),
        {
            name: 'usersSearch',
            schema: [UserEntity],
        },
    ),
    listFriends: new Endpoint(
        () => axios.get(`${baseUrlFriends}`).then(resp => resp.data),
        {
            name: 'userFriendList',
            schema: [UserEntity],
            pollFrequency: 5 * 60 * 1000,
        },
    ),
}
