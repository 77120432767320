import { Avatar, Tooltip } from "@mui/material";
import { useUser } from "hooks/user-hooks";
import PropTypes from "prop-types";
import { filesBaseUrl } from 'store/backend-config';

const UserAvatar = ({ userId, sx, variant='avatar', ...props }) => {
    const user = useUser(userId);
    const avatarUrl = `${filesBaseUrl}/${user.image}`;

    if (variant === 'username') {
        return user?.username || 'Anonymous';
    }

    return (
        <Tooltip title={user?.username || 'Anonymous'}>
            <Avatar alt='profile user'
                    sx={{ width: 32, height: 32, ...sx }}
                    src={avatarUrl}
                    {...props}
            >
                {user?.username?.charAt(0).toUpperCase() || 'A'}
            </Avatar>
        </Tooltip>
    )
}

UserAvatar.propTypes = {
    userId: PropTypes.string.isRequired,
    variant: PropTypes.string,
    sx: PropTypes.object,
}

export default UserAvatar;
