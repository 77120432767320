import { useEntityTimerSessions, useRunningTimerSessions } from "hooks/timer-session-hooks";
import { shallowEqual, useSelector } from 'react-redux';
import { selectUserEffortsList } from 'store/selectors/endpoint-selectors';

export function useEfforts() {
    // const efforts = useLive(UserEffortResource.list);
    const efforts = useSelector(selectUserEffortsList(), shallowEqual);
    return efforts ? efforts : [];
}

export function useEntityEfforts(entityId) {
    return useEfforts().filter(effort => effort.parentId === entityId);
}

export function useUserEntityEffort(entityId, userId) {
    const effort = useEfforts().find(({ parentId, ownerId }) => parentId === entityId && ownerId === userId);
    const runningSessions = useRunningTimerSessions(entityId, userId);
    const tickingDuration = runningSessions?.length ? runningSessions[0].duration : 0;
    return effort ? effort.knownDuration + tickingDuration : tickingDuration;
}

export function useActiveEntityUsers(entityId) {
    const efforts = useEntityEfforts(entityId).map(effort => effort.ownerId);
    const sessionUsers = useEntityTimerSessions(entityId).map(session => session.ownerId);
    return Array.from(new Set([...efforts, ...sessionUsers]));
}
