import {LogoutManager} from "@data-client/react";
import {
    applyManager,
    Controller,
    createReducer, mapMiddleware,
    NetworkManager,
    PollingSubscription, PromiseifyMiddleware,
    SubscriptionManager
} from "@data-client/redux";

const restManager = new NetworkManager(30 * 60 * 1000);
const restSubscriptionManager = new SubscriptionManager(PollingSubscription);
export const dataclientController = new Controller();
export const dataclientReducer = createReducer(dataclientController);
export const dataclientSelector = (state, _action) => state.dataclient;

const logoutManager = new LogoutManager({
    handleLogout(controller) {
        localStorage.removeItem("user");
        void controller.resetEntireStore();
    },
    shouldLogout(error) {
        // 401 indicates reauthorization is needed
        return error.status === 401 || error.response?.status === 401;
    }
});

for (const manager of [logoutManager, restManager, restSubscriptionManager]) {
    manager.init?.();
}

export const dataclientMiddleware = [
    ...mapMiddleware(dataclientSelector)(
        ...applyManager([logoutManager, restManager, restSubscriptionManager], dataclientController),
    ),
    PromiseifyMiddleware
]
