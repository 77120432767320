import ImageUploadButtonNew from "components/common/ImageUploadButtonNew";
import PropTypes from "prop-types";
import { useCallback, useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { EntityTypes } from "types/enums/entity-types";
import { v4 as uuidv4 } from 'uuid';
import { useTheme, alpha } from '@mui/material/styles';
import { Button, DialogContent, DialogTitle, DialogActions, TextField, Box, Dialog, Stack } from "@mui/material";
import { useMobileDetector } from 'hooks/theme-hooks';
import { FileInfoKinds } from "types/file-info";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useEntityCover, useFileDeletionCallback } from "hooks/file-hooks";
import { useEntityCreationCallback } from "hooks/entity-hooks";
import baseBackground from 'assets/images/cardlogo.jpg';

export const CreationDialog = ({ parentId, entityType, open, onClose, ...props }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [entityId, setEntityId] = useState(uuidv4());
    const theme = useTheme();
    const mobileDevice = useMobileDetector();

    const entityCoverFile = useEntityCover(entityId);
    const entityCoverUrl = entityCoverFile?.coverUrl;
    const fileDeletionCallback = useFileDeletionCallback();

    // const [croppedImageUrl, setCroppedImageUrl] = useState('');

    const entityCreationCallback = useEntityCreationCallback();

    const imageBoxRef = useRef(null);

    const [imageBoxHeight, setImageBoxHeight] = useState(0);

    // const handleImageCropped = (croppedImage) => {
    //     setCroppedImageUrl(croppedImage);
    // };

    useEffect(() => {
        setImageBoxHeight(imageBoxRef.current?.offsetWidth
            ? imageBoxRef.current.offsetWidth / (200 / 150)
            : 20);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageBoxRef.current?.offsetWidth]);

    const handleClose = useCallback(({ entityCreated = false }) => {
        setTitle('');
        setDescription('');
        if (entityCoverFile && !entityCreated)
            void fileDeletionCallback(entityCoverFile.id);
        setEntityId(uuidv4());
        onClose();
    }, [entityCoverFile, fileDeletionCallback, onClose])

    const handleCreate = useCallback(() => {
        if ( !title?.trim())
            return;

        const entity = {
            title: title.trim(),
            id: entityId,
        };
        if (description?.trim())
            entity.description = description.trim();

        switch (entityType) {
            case 'task':
                entity.entityType = EntityTypes.TASK;
                entity.parentId = parentId;
                break;
            case 'project':
                entity.entityType = EntityTypes.PROJECT;
                break;
            case 'timer':
                entity.entityType = EntityTypes.TIMER;
                break;
        }
        entityCreationCallback(entity)
            .then(() => handleClose({ entityCreated: true }));
    }, [entityType, title, description, parentId, entityCreationCallback, handleClose, entityId]);

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='sm'
            fullScreen={mobileDevice}
            // MUI bug https://github.com/mui/material-ui/issues/33004
            // allows textfield autofocus in react strict mode
            disableRestoreFocus
            {...props}
            sx={{
                '& .MuiPaper-root': {
                    background: theme.palette.mode === 'dark' ? alpha(theme.palette.background.paper, 0.7) : theme.palette.background.default,
                },
            }}
        >
            <DialogTitle>{t(`new_${entityType}`)}</DialogTitle>
            <DialogContent>
                <Stack direction='column' spacing={2} sx={{ pt: 1 }}>
                    <TextField
                        autoFocus
                        id='title'
                        label={t(`title`)}
                        fullWidth
                        required
                        error={title.length < 1}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        id='description'
                        label={t(`description`)}
                        fullWidth
                        value={description}
                        multiline
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    { entityType !== 'task' &&
                    <Box
                        ref={imageBoxRef}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            minHeight: `${imageBoxHeight}px`,
                            maxHeight: entityCoverUrl ? `${imageBoxHeight}px` : undefined,
                            borderRadius: '6px',
                            overflow: 'hidden',
                            backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.common.black, 0.6)} 0%,
                            ${alpha(theme.palette.common.black, 0)} 45%), url(${entityCoverUrl ? entityCoverUrl : baseBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            flexGrow: 1,
                            position: 'relative',
                            'WebkitTapHighlightColor': 'transparent',
                        }}
                    >
                        <ImageUploadButtonNew
                            sx={{
                                color: '#fff',
                                '&:hover': { color: 'primary.main' },
                                position: 'absolute',
                                top: 12,
                                right: 12,
                                zIndex: 2,

                            }}
                            aria-label='upload file'
                            parentId={entityId}
                            fileKind={FileInfoKinds.ENTITY_COVER}
                            // onUpload={handleImageCropped}
                        >
                            <AddAPhotoIcon sx={{ height: 24 }} />
                        </ImageUploadButtonNew>
                    </Box>}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>{t(`cancel`)}</Button>
                <Button onClick={handleCreate} variant='contained' disabled={ !title.trim()}>{t(`create`)}</Button>
            </DialogActions>
        </Dialog>
    );
};

CreationDialog.propTypes = {
    parentId: PropTypes.string,
    entityType: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}
