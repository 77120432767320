import { AsyncBoundary } from '@data-client/react';
import EntityListSubscriber from 'components/subscribers/EntityListSubscriber';
import RunningTimerSessionSubscriber from 'components/subscribers/RunningTimerSessionSubscriber';
import MainTimerTicker from 'components/timers/MainTimerTicker';
import { useMobileDetector } from 'hooks/theme-hooks';
import { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, IconButton } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { drawerWidth } from 'config';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// types
import { openDrawer, setFriendsDrawerOpen } from 'store/reducers/slice-menu';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const mobileDevice = useMobileDetector();
    const dispatch = useDispatch();

    const drawerOpen = useSelector((state) => state.menu.drawerOpen);
    const friendsDrawerOpen = useSelector((state) => state.menu.friendsDrawerOpen);

    const marginLeft = drawerOpen
        ? drawerWidth
        : friendsDrawerOpen ? 350 : 50;

    const mainContentMargin = friendsDrawerOpen && !mobileDevice ? 300 : 0;


    const handleDrawerToggle = () => {
        dispatch(openDrawer({ drawerOpen: !drawerOpen }));
        dispatch(setFriendsDrawerOpen(false));
    };

    const iconButtonStyle = {
        position: 'fixed',
        left: drawerOpen ? drawerWidth : '30px',
        top: '45%',
        width: '8px',
        '&:hover': {},
        transform: 'translateY(-45%)',
        zIndex: 1301,
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
        })
    };

    const iconStyle = {
        transform: 'scale(0.8, 3)',
        color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary[100],
        stroke: theme.palette.mode === 'dark' ? theme.palette.primary[1000] : theme.palette.primary[200],
        strokeWidth: 0.3,
        '&:hover': {
            color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
            stroke: theme.palette.mode === 'dark' ? theme.palette.primary[700] : theme.palette.primary.dark
        }
    };

    // set media wise responsive drawer
    useEffect(() => {
        dispatch(openDrawer({ drawerOpen: !mobileDevice }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobileDevice]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <MainTimerTicker />
            <EntityListSubscriber />
            <RunningTimerSessionSubscriber />

            <Header marginLeft={marginLeft} />
            <Drawer open={drawerOpen} />
            <IconButton disableRipple aria-label='open drawer' onClick={handleDrawerToggle} sx={iconButtonStyle}>
                {!mobileDevice && (!drawerOpen ? <KeyboardArrowRightIcon sx={iconStyle} /> :
                    <KeyboardArrowLeftIcon sx={iconStyle} />)}
            </IconButton>
            <Box
                component='main'
                sx={{
                    width: '100%',
                    flexGrow: 1,
                    p: { xs: 0, sm: 1, md: 2, lg: 2 },
                    pb: { xs: 8, sm: 8 },
                    pt: { xs: 2, sm: 2 },
                    marginLeft: `${mainContentMargin}px`,
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }}
            >
                <Toolbar />
                <Breadcrumbs navigation={navigation} title />
                <AsyncBoundary>
                    <Outlet />
                </AsyncBoundary>
            </Box>
        </Box>
    );
};

MainLayout.whyDidYouRender={
    logOnDifferentValues: false,
};

export default memo(MainLayout);
