import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ThreeDotsMenuButton from 'components/common/ThreeDotsMenuButton';
import UserAvatar from 'components/users/UserAvatar';
import UserMenu from 'components/users/UserMenu';
import { useUser } from 'hooks/user-hooks';
import PropTypes from 'prop-types';

/**
 * MUI ListItem for user display
 * @param {string} userId - user id
 * @param [props] passed to ListItem
 * @returns {JSX.Element}
 * @constructor
 */
const UserListItem = ({ userId, ...props }) => {
    const user = useUser(userId);

    return (
        <ListItem
            {...props}
        >
            <ListItemAvatar>
                <UserAvatar userId={userId} />
            </ListItemAvatar>
            <ListItemText primary={user?.username} />
            <ThreeDotsMenuButton menuContents={<UserMenu user={user} />} />
        </ListItem>
    )
}

UserListItem.propTypes = {
    userId: PropTypes.string.isRequired,
}
export default UserListItem;
