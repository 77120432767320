// third-party
import { configureStore } from '@reduxjs/toolkit';

// project import
import menuReducer from './reducers/slice-menu';
import authReducer from './reducers/slice-auth';
import themeReducer from './reducers/slice-theme';
import timerCardTypeReducer from './reducers/slice-timer-cardtype';
import projectCardTypeReducer from './reducers/slice-project-cardtype';

import { dataclientReducer, dataclientMiddleware } from "./dataclient-store";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        menu: menuReducer,
        auth: authReducer,
        theme: themeReducer,
        dataclient: dataclientReducer,
        timerCardType: timerCardTypeReducer,
        projectCardType: projectCardTypeReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: {
                // Ignore state paths, e.g. state for 'items':
                ignoredPaths: ['dataclient']
            },
            serializableCheck: {
                ignoredActions: [
                    'rdc/set',
                    'rdc/fetch',
                    'rdc/subscribe',
                    'rdc/unsubscribe',
                    'rdc/invalidateall',
                    'rdc/expireall',
                ],
                // Ignore these paths in the state
                ignoredPaths: ['dataclient', 'rdc'],
            }
        })
            .concat(dataclientMiddleware)
});

const { dispatch } = store;

export { store, dispatch };
