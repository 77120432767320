import { schema } from "@data-client/endpoint";
import { EntityTypes } from "types/enums/entity-types";
import FileInfo from "types/file-info";

export const FileInfoEntity = schema.Entity(
    FileInfo,
    {
        key: EntityTypes.FILE_INFO,
    }
)
