import { useController } from "@data-client/react";
import { useCallback, useEffect } from "react";
import { FileResource } from "store/dataclient/file-resource";
import { FileInfoKinds } from "types/file-info";

import { filesBaseUrl } from "store/backend-config";
import { comparatorCreatedAtDesc } from "utils/comparators";
import { selectFiles } from 'store/selectors/entity-selectors';
import { shallowEqual, useSelector } from 'react-redux';
import { selectEndpointMeta } from 'store/selectors/dataclient-selectors';

export const useFileUploadCallback = () => {
    const restCtrl = useController();

    return useCallback(({ parentId, kind, file, filename }) => {
        return restCtrl.fetch(FileResource.upload, { parentId: parentId, kind: kind, file: file, filename: filename });
    }, [restCtrl]);
};

export const useFileDeletionCallback = () => {
    const restCtrl = useController();
    return useCallback(
        fileId => restCtrl.fetch(FileResource.delete, { id: fileId }),
        [restCtrl]);
};

// TODO fix entityFiles
export const useEntityFiles = (entityId) => {
    const restCtrl = useController();
    const files = useSelector(selectFiles({ parents: [entityId] }), shallowEqual);
    const meta = useSelector(selectEndpointMeta(FileResource.listEntityFiles, { entityId: entityId }));

    useEffect(() => {
        if (!meta && entityId)
            void restCtrl.fetch(FileResource.listEntityFiles, { entityId: entityId });
        // eslint-disable-next-line
    }, []);

    return files ? files : [];
    // return useSuspenseRedux(FileResource.listEntityFiles, { entityId: entityId });
};

export const useEntityCover = (entityId) => {
    const files = useEntityFiles(entityId);
    if (files.length > 0) {
        const out = files.filter(fileInfo => fileInfo.fileKind === FileInfoKinds.ENTITY_COVER)
            .toSorted(comparatorCreatedAtDesc)[0];
        if (out)
            out.coverUrl = `${filesBaseUrl}/${out.previewPath}`;
        return out;
    }
    return null;
};

export const useEntityCoverUrl = (entityId) => {
    const coverFile = useEntityCover(entityId);
    if (coverFile)
        return `${filesBaseUrl}/${coverFile.previewPath}`;

    return null;
};
