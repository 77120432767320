import 'react-advanced-cropper/dist/style.css';
import { useFileUploadCallback } from "hooks/file-hooks";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { Backdrop, CircularProgress, Dialog, IconButton, Portal, Tooltip } from "@mui/material";
import { useMobileDetector } from "hooks/theme-hooks";
import { CircleStencil, Cropper, RectangleStencil } from "react-advanced-cropper";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { FileInfoKinds } from "types/file-info";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const ImageUploadDialog = ({
                               parentId,
                               fileKind = FileInfoKinds.ATTACHMENT,
                               triggerFileInput = true, open = false,
                               isAvatar = false,
                               onUpload, onClose, ...props
                           }) => {
    const { t } = useTranslation();

    const mobileDevice = useMobileDetector();

    const fileInputRef = useRef(null);

    const cropperRef = useRef(null);
    const [image, setImage] = useState(null);
    const stencilComponent = isAvatar ? CircleStencil : RectangleStencil;

    const [backdropOpened, setBackdropOpened] = useState(false);
    const [cropperDialogOpened, setCropperDialogOpened] = useState(open);

    const uploadCallback = useFileUploadCallback();

    useEffect(() => {
        if (triggerFileInput) {
            if (fileInputRef?.current) {
                handleFileInputOpen();
                onClose();
            }
        }
    }, [triggerFileInput, fileInputRef, onClose]);

    useEffect(() => {
        setCropperDialogOpened(open);
    }, [open]);

    useEffect(() => {
        if (cropperRef?.current) {
            const stencilWidth = cropperRef.current.getContainerData().width * 0.7;
            const stencilHeight = cropperRef.current.getContainerData().height * 0.525;
            cropperRef.current.setStencilSize({ width: stencilWidth, height: stencilHeight });
        }
    }, [cropperRef]);

    const handleFileInputOpen = () => {
        if (fileInputRef?.current)
            fileInputRef.current.click();
    }

    const handleFileSelect = (event) => {
        const { files } = event.target;
        if (files && files[0]) {
            const blob = URL.createObjectURL(files[0]);
            // Get the image type from the extension. It's the simplest way, though be careful it can lead to an incorrect result:
            setImage({ src: blob, type: files[0].type, name: files[0].name });

            if (backdropOpened)
                setBackdropOpened(false);
            if ( !cropperDialogOpened)
                setCropperDialogOpened(true);
        }
        // Clear the event target value to give the possibility to upload the same image:
        event.target.value = '';
    };

    const handleFileUpload = () => {
        const canvas = cropperRef.current?.getCanvas();
        if (canvas) {
            setBackdropOpened(true);
            canvas.toBlob(blob => {
                if (blob) {
                    uploadCallback({ parentId, kind: fileKind, file: blob, filename: image.name })
                        .then((result) => {
                            onUpload && onUpload(result);
                            handleDialogClose();
                        });
                }
            }, image.type);
        }
    }

    const handleDialogClose = () => {
        setCropperDialogOpened(false);
        setBackdropOpened(false);
        if (typeof onClose === 'function')
            onClose();
    }

    return createPortal(
        <>
            <input
                ref={fileInputRef}
                type='file'
                accept='image/*'
                onInput={handleFileSelect}
                hidden
            />
            {cropperDialogOpened &&
                <Dialog
                    open={cropperDialogOpened}
                    fullWidth
                    maxWidth='sm'
                    fullScreen={mobileDevice}
                    onClose={handleDialogClose}
                    PaperProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                        },
                    }}
                    {...props}
                >
                    <Cropper
                        ref={cropperRef}
                        src={image && image.src}
                        stencilComponent={stencilComponent}
                        stencilProps={{
                            aspectRatio: isAvatar ? 1 : 200 / 150,
                            movable: true,
                            resizable: true,
                        }}
                        style={{
                            width: '100%',
                            height: '100%',
                            zIndex: 1,
                            backgroundColor: '#000',
                        }}
                    />
                    <Tooltip title={t('crop')}>
                        <IconButton
                            onClick={handleFileUpload}
                            sx={{
                                position: 'absolute',
                                height: 50,
                                width: 50,
                                borderRadius: '50%',
                                bottom: 17,
                                right: 15,
                                zIndex: 2,
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            }}
                        >
                            <DoneIcon sx={{ width: 40, height: 40 }} />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title={t('select_other_file')}>
                        <IconButton
                            variant='box-card'
                            onClick={handleFileInputOpen}
                            sx={{
                                position: 'absolute',
                                bottom: 17,
                                right: 45,
                                zIndex: 2,
                            }}
                        >
                            <AddAPhotoIcon sx={{ height: 24 }} />
                        </IconButton>
                    </Tooltip> */}
                    <IconButton
                        variant='box-card'
                        onClick={handleDialogClose}
                        sx={{
                            position: 'absolute',
                            top: 7,
                            right: 7,
                            zIndex: 2,
                        }}
                    >
                        <CloseIcon sx={{ height: 14 }} />
                    </IconButton>
                </Dialog>
            }

            {backdropOpened &&
                <Portal>
                    <Backdrop open={true} sx={{ color: '#fff', zIndex: 4000 }}>
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Portal>
            }
        </>, document.getElementById('modal'))
}

ImageUploadDialog.propTypes = {
    parentId: PropTypes.string.isRequired,
    fileKind: PropTypes.string,
    onUpload: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    triggerFileInput: PropTypes.bool,
    isAvatar: PropTypes.bool,
}

export default ImageUploadDialog;
