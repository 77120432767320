import { useController } from '@data-client/react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StarResource } from 'store/dataclient/star-resource';
import { selectStarList } from 'store/selectors/endpoint-selectors';


export const useStarList = () => {
    // const starList = useSuspense(StarResource.list);
    const starList = useSelector(selectStarList());
    return starList ? starList : [];
};

export const useStarControls = (entityId) => {
    const restCtrl = useController();
    const starList = useStarList();
    const starred = Boolean(starList?.includes(entityId));

    const addStar = useCallback(
        () => restCtrl.fetch(StarResource.star, { id: entityId }),
        // eslint-disable-next-line
        [entityId]);

    const removeStar = useCallback(
        () => restCtrl.fetch(StarResource.unstar, { id: entityId }),
        // eslint-disable-next-line
        [entityId]);

    return useMemo(() => ({ starred, addStar, removeStar })
        // eslint-disable-next-line
        , [starred, entityId]);
};

export const useStarComparator = () => {
    const starList = useStarList();

    return useCallback((a, b) => compareArrayInclusion(a.id, b.id, starList), [starList]);
};

function compareArrayInclusion(a, b, checkArray) {
    if ( !checkArray?.length > 0)
        return 0;
    if ( !checkArray.includes(a) && checkArray.includes(b))
        return 1;
    if (checkArray.includes(a) && !checkArray.includes(b))
        return -1;
    return 0;
}
