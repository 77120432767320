import EntityFieldTextInput from "components/common/EntityFieldTextInput";
import React, { useState, useRef } from 'react';
import {
    Box, AvatarGroup, List, ListItem, ListItemAvatar,
    ListItemText, Popper, ClickAwayListener, Paper, AppBar, Tabs, Tab, Grid, Typography, Stack
} from "@mui/material";
import CreationButton from "components/common/CreationButton";
import SessionsList from "components/common/SessionsList";
import { ShareButton } from "components/shares/ShareButton";
import TaskList from "components/tasks/TaskList";
import { useOwnership, useShares } from "hooks/share-hooks";
import { useProjectTasks } from "hooks/task-hooks";
import { useEntityTimerSessions, usePaginatedSessions } from "hooks/timer-session-hooks";
import { useParams } from "react-router-dom";
import { useTheme, alpha } from '@mui/material/styles';
import { useEntityCoverUrl } from "hooks/file-hooks";
import baseBackground from 'assets/images/cardlogo.jpg';
import TimerControlButton from "components/timers/TimerControlButton";
import { useMobileDetector } from 'hooks/theme-hooks';
import UserAvatar from "components/users/UserAvatar";
import TimerChipEntityUser from "components/timers/chips/TimerChipEntityUser";
import { bottomNavigationHeight } from "config";

import { useProject } from "hooks/project-hooks";
import { useActiveEntityUsers } from "hooks/user-effort-hooks";
import { useTranslation } from 'react-i18next';
import Transitions from 'components/@extended/Transitions';
import { useEntityControls } from "hooks/entity-hooks";
import LineChartWidget from "components/charts/LineChartWidget";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import TimelapseIcon from '@mui/icons-material/Timelapse';

export const ProjectPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { projectId } = useParams();


    const project = useProject(projectId);
    const projectUsers = useActiveEntityUsers(project.id);
    let sharedWith = useShares(project.id);

    const mobileDevice = useMobileDetector();
    const isOwnProject = useOwnership(project.ownerId);
    const tasks = useProjectTasks(projectId);
    const entityCoverUrl = useEntityCoverUrl(project.id);
    const { entityUpdate: projectUpdateCallback } = useEntityControls(projectId);

    const allSessions = useEntityTimerSessions(projectId);
    const { currentSessions, loadMoreSessions, hasMore } = usePaginatedSessions(allSessions);

    if(!isOwnProject && !(sharedWith?.includes(project.ownerId))){
        sharedWith = [project.ownerId, ...sharedWith];
    }

    const [openPopper, setOpenPopper] = useState(false);
    const anchorRef = useRef(null);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAvatarClick = () => {
        setOpenPopper((prevOpen) => !prevOpen);
    };

    return (
        <>
            <Box
                display='flex'
                alignItems='flex-start'
                justifyContent='flex-start'
                sx={{
                    position: 'relative',
                    direction: 'column',
                    width: '100%',
                    height: '250px',
                    p: 2,
                    borderRadius: '6px',
                    backgroundImage:
                        `linear-gradient(to bottom, ${alpha(theme.palette.common.black, 0.4)} 0%,
                        ${alpha(theme.palette.common.black, 0)} 45%),
                        url(${entityCoverUrl || baseBackground})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <EntityFieldTextInput
                        value={project.title}
                        onCommit={newValue => projectUpdateCallback({ title: newValue })}
                    />

                    {isOwnProject && <ShareButton variant='box-card' parentId={projectId} />}
                    {( !isOwnProject || sharedWith?.length > 0) &&
                        <Stack
                            direction='row'
                            sx={{
                                position: 'absolute',
                                right: '12px',
                                bottom: '64px',
                            }}
                        >

                            <ShareOutlinedIcon
                                sx={{ width: 32, height: 32 }}
                            />
                            <AvatarGroup>
                                {/*{ !isOwnProject && */}
                                {/*    <UserAvatar userId={project.ownerId} />*/}
                                {/*}*/}
                                {sharedWith?.length > 0 &&
                                    sharedWith.map(shareTarget =>
                                        <UserAvatar
                                            key={shareTarget}
                                            userId={shareTarget}
                                        />
                                    )
                                }
                            </AvatarGroup>
                        </Stack>
                    }
                    <Stack
                        direction='row'
                        sx={{
                            position: 'absolute',
                            right: '12px',
                            bottom: '12px',
                        }}
                    >
                        <TimelapseIcon sx={{ width: 32, height: 32 }} />
                        <AvatarGroup
                            ref={anchorRef}
                            onClick={handleAvatarClick}
                            max={mobileDevice ? 2 : 4}
                        >
                            {projectUsers.map(userId =>
                                <UserAvatar
                                    key={userId}
                                    userId={userId}
                                    sx={{
                                        '&.MuiAvatar-root': { borderWidth: 2, borderColor: alpha('#fff', 0.2) },
                                        '&.MuiAvatar-root:hover': { borderColor: alpha('#fff', 0.6) },
                                    }}
                                />
                            )
                            }
                        </AvatarGroup>
                    </Stack>
                    <Popper
                        open={openPopper}
                        anchorEl={anchorRef.current}
                        transition
                        placement='bottom-end'
                    >
                        {({ TransitionProps }) => (
                            <Transitions type='fade' {...TransitionProps}>
                                <ClickAwayListener onClickAway={() => setOpenPopper(false)}>
                                    <Paper elevation={1}
                                           sx={{
                                               boxShadow: theme.customShadows.z1,
                                               border: 1,
                                               borderColor: theme.palette.secondary.lighter,
                                           }}>
                                        <List>
                                            {projectUsers.map(userId =>
                                                <ListItem key={userId} sx={{ p: 1 }}>
                                                    <ListItemAvatar key={userId}>
                                                        <UserAvatar
                                                            key={userId}
                                                            userId={userId}
                                                            sx={{
                                                                '&.MuiAvatar-root': {
                                                                    borderWidth: 2,
                                                                    borderColor: alpha('#fff', 0.2)
                                                                },
                                                                '&.MuiAvatar-root:hover': { borderColor: alpha('#fff', 0.6) }
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText id={userId}>
                                                        <TimerChipEntityUser key={userId} entityId={projectId}
                                                                             userId={userId}
                                                                             isTextLine />
                                                    </ListItemText>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Paper>
                                </ClickAwayListener>
                            </Transitions>
                        )}
                    </Popper>
                </Box>
                <TimerControlButton
                    variant='box'
                    parentId={project.id}
                    sx={{ position: 'absolute', bottom: 20, left: 20, transition: 'all 0.4  s ease' }}
                />
                <CreationButton entityId={projectId} entityType='task' />
            </Box>

            {!mobileDevice ? (
                <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item md={8}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Typography variant='h5' sx={{ p: 1 }}>
                                {t('tasks')}
                            </Typography>
                            <TaskList tasks={tasks} projectId={projectId} />
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper elevation={0} sx={{ pt: 2 }}>
                            <Typography variant='h5' sx={{ p: 1 }}>
                                {t('sessions')}
                            </Typography>
                            <Box sx={{ pl: 1 }}>
                                <LineChartWidget
                                    projectId={projectId}
                                    projectUsers={projectUsers}
                                />
                            </Box>
                            <SessionsList
                                sessions={currentSessions}
                                fetchMoreSessions={loadMoreSessions}
                                hasMoreSessions={hasMore}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                 <Box sx={{ flexGrow: 1 }}>
                     <AppBar position='static' color='default' elevation={1}
                             sx={{
                                 position: 'fixed',
                                 bottom: bottomNavigationHeight,
                                 borderTop: `1px solid ${theme.palette.divider}`,
                                 zIndex: 1100,
                                 height: 40,
                                 mt: 0

                             }}>
                         <Tabs
                             value={value}
                             onChange={handleChange}
                             indicatorColor='primary'
                             textColor='primary'
                             variant='fullWidth'
                             sx={{
                                 minHeight: '40px',
                                 '& .MuiTab-root': {
                                     minHeight: '40px',
                                     padding: '6px 0',
                                 },
                             }}
                         >
                             <Tab label={t('tasks')} />
                             <Tab label={t('sessions')} />
                         </Tabs>
                     </AppBar>
                     {value === 0 && <TaskList tasks={tasks} projectId={projectId} />}
                     {value === 1 && (
                         <>                  <Box sx={{ pt: 1, pl: 1 }}>
                             <LineChartWidget
                                 projectId={projectId}
                                 projectUsers={projectUsers}
                             />
                         </Box>
                             <SessionsList
                                 sessions={currentSessions}
                                 fetchMoreSessions={loadMoreSessions}
                                 hasMoreSessions={hasMore}
                             />
                         </>
                     )
                     }
                 </Box>
             )}
        </>
    );
};

ProjectPage.propTypes = {}

export default ProjectPage;
