// assets
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import Groups3Icon from '@mui/icons-material/Groups3';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import GroupIcon from '@mui/icons-material/Group';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: DashboardOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'timers-page',
            title: 'Timers Table',
            type: 'item',
            url: '/timers/table',
            icon: TableViewOutlinedIcon
        },
        {
            id: 'timers-grid',
            title: 'Timers Grid',
            type: 'item',
            url: '/timers/grid',
            icon: TimelapseIcon,
            breadcrumbs: false
        },
        {
            id: 'projects-grid',
            title: 'Projects Grid',
            type: 'item',
            url: '/projects',
            icon: ChecklistIcon,
            breadcrumbs: false
        },
        {
            id: 'groups-table',
            title: 'Groups',
            type: 'item',
            url: '/groups',
            icon: Groups3Icon,
            breadcrumbs: false
        },
        {
            id: 'friends-page',
            title: 'Friends',
            type: 'item',
            url: '/profile/friends',
            icon: GroupIcon,
            breadcrumbs: false
        },
    ]
};

export default dashboard;
