import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, CircularProgress } from '@mui/material';
import { AsyncBoundary } from '@data-client/react';
import TimerSessionItem from 'components/timers/TimerSessionItem';

const SessionsList = ({ sessions, fetchMoreSessions, hasMoreSessions }) => {
    const observer = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const lastSessionElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreSessions) {
                setIsLoading(true);
                fetchMoreSessions().then(() => setIsLoading(false));
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMoreSessions, fetchMoreSessions]);

    return (
        <AsyncBoundary>
            <Grid container spacing={1} sx={{ pt: 2 }}>
                {sessions.map((session, index) => (
                    <Grid key={session.id} item xs={12} sx={{ p: 0.5 }} ref={index === sessions.length - 1 ? lastSessionElementRef : null}>
                        <TimerSessionItem sessionId={session.id} />
                    </Grid>
                ))}
                {isLoading && (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            <Box sx={{ height: '110px' }} />
        </AsyncBoundary>
    );
}

SessionsList.propTypes = {
    sessions: PropTypes.array.isRequired,
    fetchMoreSessions: PropTypes.func.isRequired,
    hasMoreSessions: PropTypes.bool.isRequired,
}

export default SessionsList;
