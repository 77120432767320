import PropTypes from 'prop-types';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useOwnership } from 'hooks/share-hooks';
import { useFriendshipRequestControls } from 'hooks/user-friendship-hooks';


import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

/**
 * MUI List holding menu to interact with friendship request
 * @param {object} request friendship request object
 * @param [props] passed to List
 * @returns {JSX.Element}
 * @constructor
 */
const FriendshipRequestMenu = ({ request, ...props }) => {
    const isOwnRequest = useOwnership(request.ownerId);
    const { requestRejectCallback, requestAcceptCallback, requestCancelCallback } = useFriendshipRequestControls(request.id);

    return (
        <List
            {...props}
        >
            {isOwnRequest
                ? <>
                    <ListItemButton onClick={requestCancelCallback}>
                        <ListItemIcon>
                            <CancelOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'menu_friendship_request_cancel'} />
                    </ListItemButton>
                </>
                : <>
                    <ListItemButton onClick={requestAcceptCallback}>
                        <ListItemIcon>
                            <CheckOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'menu_friendship_request_accept'} />
                    </ListItemButton>
                    <ListItemButton onClick={requestRejectCallback}>
                        <ListItemIcon>
                            <CancelOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={'menu_friendship_request_reject'} />
                    </ListItemButton>
                </>
            }
        </List>
    )
}

FriendshipRequestMenu.propTypes = {
    request: PropTypes.object.isRequired,
}

export default FriendshipRequestMenu;