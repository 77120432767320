import { EntityTypes } from "types/enums/entity-types";

export default class FileInfo {
    id = '';
    createdAt = '';
    originalName = '';
    fileSize = 0;
    owner = '';
    filePath = '';
    thumbPath = '';
    previewPath = '';
    parentId = '';
    sortOrder = 0;
    mimeType = '';
    fileKind = '';

    entityType = EntityTypes.FILE_INFO;
}

export const FileInfoKinds = {
    ATTACHMENT: 'ATTACHMENT',
    ENTITY_COVER: 'ENTITY_COVER',
    USER_AVATAR: 'USER_AVATAR',
}
