export default function Dialog() {
    return {
        MuiDialog: {

            styleOverrides: {
                root: {
                paper: {

                    backgroundColor: 'rgba(0, 0, 0, 1)',

                },
                backdrop: {

                    backgroundColor: 'rgba(0, 0, 0, 0.8)',

            },
        },
        },

        },
    };
}
