// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    defaultId: 'dashboard',
    openComponent: 'buttons',
    drawerOpen: false,
    componentDrawerOpen: true,
    friendsDrawerOpen: false,
};

// ==============================|| SLICE - MENU ||============================== //

const sliceMenu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },

        toggleFriendsDrawer(state) {
            state.friendsDrawerOpen = !state.friendsDrawerOpen;
        },

        setFriendsDrawerOpen(state, action) {
            state.friendsDrawerOpen = action.payload;
        },
    }
});

export default sliceMenu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, toggleFriendsDrawer, setFriendsDrawerOpen } = sliceMenu.actions;
