import { Card, CardActionArea, ClickAwayListener, Fade, Paper, Popper, SwipeableDrawer } from '@mui/material';
import { useMobileDetector } from 'hooks/theme-hooks';
import PropTypes from 'prop-types';
import { cloneElement, forwardRef, Suspense, useEffect, useMemo, useRef, useState } from 'react';

/**
 * React component, opens a popper (on PC) or drawer (on mobile) with specified contens when clicked
 * @example
 * <PopperMenuButton popperContents={<div>PREVED</div>} popperProps={{placement: 'top-start'}}>
 *     <Button>TEST</Button>
 * </PopperMenuButton>
 * @param {JSX.Element} children react node to act as a button, something onClick()-able
 * @param {JSX.Element} popperContents react node to display inside the popper,
 * may have onClose prop to signal the popper to close itself
 * @param {object} [popperProps] properties to apply to the Popper component
 * @param {object} [popperProps] properties to apply to the SwipeableDrawer component
 * @param {function()} [onClick] callback to append to child's onClick callback
 * @param {function()} [onClose] callback to call when popper is being closed
 * @param [ref] forwarded to children, overwriting existing ref if any
 * @returns {JSX.Element}
 */
const PopperMenuButton = forwardRef(({ children, popperContents, onClick, onClose, popperProps, drawerProps }, ref) => {
    const popperAnchorRef = useRef(ref);
    const popperWasOpen = useRef(null);
    const isMobileDevice = useMobileDetector();

    const [isPopperOpen, setPopperOpen] = useState(false);

    const button = useMemo(() => cloneElement(
        children,
        {
            ref: popperAnchorRef,
            onClick: (event) => {
                event.preventDefault();
                setPopperOpen(open => !open);
                if (typeof onClick === 'function')
                    onClick();
            },
        },
    ), [onClick, children]);

    const contents = cloneElement(
        popperContents,
        {
            onClose: () => {
                setPopperOpen(false);
            },
        },
    );

    // call top level onClose if there is any
    useEffect(() => {
        if (isPopperOpen)
            popperWasOpen.current = true;
        else {
            if (popperWasOpen.current
                && typeof onClose === 'function') {
                onClose();
            }
            popperWasOpen.current = false;
        }
        // eslint-disable-next-line
    }, [isPopperOpen]);

    return (
        <>
            {button}

            {isMobileDevice
             ? <SwipeableDrawer
                 anchor='bottom'
                 open={isPopperOpen}
                 onClose={() => setPopperOpen(false)}
                 onOpen={() => null}
                 onClick={(event) => event.stopPropagation() /* preventDefault breaks file input */ }
                 disableSwipeToOpen
                 disableBackdropTransition
                 {...drawerProps}
             >
                 <Paper sx={{ width: '100%', minHeight: '250px' }}>
                     <Suspense fallback={<div>Loading...</div>}>
                         {contents}
                     </Suspense>
                 </Paper>
             </SwipeableDrawer>

             :
             <Popper
                 open={isPopperOpen}
                 anchorEl={popperAnchorRef.current}
                 placement='bottom-end'
                 transition
                 sx={{ zIndex: 'modal' }}
                 {...popperProps}
             >
                 {({ TransitionProps }) => (
                     <ClickAwayListener
                         onClickAway={() => setPopperOpen(false)}
                     >
                         <Fade {...TransitionProps} timeout={200}>
                             {/* Card and CardActionArea is here to intercept onClick events from the children components
                             and prevent bubbling them to the parent container */}
                             <Card>
                                 <CardActionArea
                                     disableRipple
                                     onClick={(event) => {
                                         event.stopPropagation(); /* preventDefault breaks file input */
                                     }}
                                 >
                                     <Suspense fallback={<div>Loading...</div>}>
                                         {contents}
                                     </Suspense>
                                 </CardActionArea>
                             </Card>
                         </Fade>
                     </ClickAwayListener>
                 )}
             </Popper>
            }
        </>
    )
})

PopperMenuButton.propTypes = {
    children: PropTypes.element.isRequired,
    popperContents: PropTypes.element,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    popperProps: PropTypes.object,
    drawerProps: PropTypes.object,
}

export default PopperMenuButton;