import { EntityTypes } from "types/enums/entity-types";

export default class ApiMessage {
    id = '';
    message = '';
    status = '';
    entity = null;
    entityType = EntityTypes.API_MESSAGE;
}

export const ApiMessageStatus = {
    OK: 'OK',
    ERROR: 'ERR'
}
