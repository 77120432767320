// assets
import LoginIcon from '@mui/icons-material/Login';

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'authentication',
    title: 'Authentication',
    type: 'group',
    children: [
        {
            id: 'login1',
            title: 'Login',
            type: 'item',
            url: '/login',
            icon: LoginIcon,
            target: true
        }
    ]
};

export default pages;
