import PropTypes from 'prop-types';

import PopperMenuIconButton from 'components/common/PopperMenuIconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/**
 * Default three-dots MUI icon button, opens menu
 * @param {JSX.Element} menuContents menu component to display
 * @param [props] passed to IconButton
 * @returns {JSX.Element}
 * @constructor
 */
const ThreeDotsMenuButton = ({ menuContents, ...props }) => {
    return (
        <PopperMenuIconButton
            popperContents={menuContents}
            {...props}
        >
            <MoreVertIcon />
        </PopperMenuIconButton>
    )
}

ThreeDotsMenuButton.propTypes = {
    menuContents: PropTypes.element.isRequired,
}

export default ThreeDotsMenuButton;