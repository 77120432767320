import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationDialog from 'components/common/ConfirmationDialog';
import TextInputDialog from 'components/common/TextInputDialog';
import { useTimerSessionControls } from 'hooks/timer-session-hooks';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

/**
 * MUI List, menu with timer session related actions
 * @param {object} session timer session object
 * @param {function()} [onClose] callback to call when the menu closes
 * @param [props] passed to List
 * @returns {JSX.Element}
 * @constructor
 */
const TimerSessionMenu = ({ session, onClose, ...props }) => {
    const { t } = useTranslation();
    const sessionId = session?.id;
    const { sessionArchive, sessionDelete, sessionSetDescription } = useTimerSessionControls(sessionId);

    const [isCommentDialogOpen, setCommentDialogOpen] = useState(false);
    const [isDeleteDialogOpened, setDeleteDialogOpened] = useState(false);

    if ( !onClose)
        onClose = () => null;

    return (
        <>
            <List
                {...props}
            >
                <ListItemButton onClick={() => setCommentDialogOpen(true)}>
                    <ListItemIcon>
                        <CommentOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={'menu_timer_session_comment'} />
                </ListItemButton>
                <ListItemButton onClick={() => sessionArchive() || onClose()}>
                    <ListItemIcon>
                        <ArchiveOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('archive')} />
                </ListItemButton>
                <ListItemButton onClick={() => setDeleteDialogOpened(true)}>
                    <ListItemIcon>
                        <DeleteOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('delete')} />
                </ListItemButton>
            </List>
            <TextInputDialog
                onClose={() => {
                    setCommentDialogOpen(false);
                    onClose();
                }}
                onCommit={newValue => sessionSetDescription(newValue)}
                title={'dialog_timer_session_comment_title'}
                text={'dialog_timer_session_comment_text'}
                open={isCommentDialogOpen}
                value={session.description}
            />
            <ConfirmationDialog
                onClose={() => {
                    setDeleteDialogOpened(false);
                    onClose()
                }}
                onConfirm={() => sessionDelete()}
                text={t('are_you_sure_to_delete_this_session')}
                confirmActionLabel={t('delete')}
                open={isDeleteDialogOpened}
            />
        </>
    )
}

TimerSessionMenu.propTypes = {
    onClose: PropTypes.func,
    session: PropTypes.object.isRequired,
}

export default TimerSessionMenu;