import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import FriendshipRequestList from 'components/users/FriendshipRequestList';
import UserFriendsList from 'components/users/UserFriendsList';
import UserSearchWidget from 'components/users/UserSearchWidget';
import { useTranslation } from 'react-i18next';

const FriendsPage = () => {
    const [isSearching, setIsSearching] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <UserSearchWidget setIsSearching={setIsSearching} />
            {!isSearching && (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ p: 1 }}>
                            {t('friendship_requests')}
                        </Typography>
                        <FriendshipRequestList />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ p: 1 }}>
                            {t('friends')}
                        </Typography>
                        <UserFriendsList />
                    </Grid>
                </Grid>)}
        </>
    )
}

export default FriendsPage;