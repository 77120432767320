import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import ProtectedRoute from "routes/ProtectedRoute";

const ProfileSettingsPage = Loadable(lazy(() => import('pages/extra-pages/ProfileSettingsPage')));
const FriendsPage = Loadable(lazy(() => import('pages/extra-pages/FriendsPage')));

const ProfileMenuRoutes = {
    path: '/profile',
    element: <ProtectedRoute element={<MainLayout />} />,
    children: [
        {
            path: 'settings',
            element: <ProfileSettingsPage />,
        },
        {
            path: 'friends',
            element: <FriendsPage />,
        },
    ],
}

export default ProfileMenuRoutes;
