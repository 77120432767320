import { List } from '@mui/material';
import UserListItem from 'components/users/UserListItem';
import { useUserFriends } from 'hooks/user-friendship-hooks';

/**
 * MUI List to display user's friends
 * @param [props] passed to List
 * @returns {JSX.Element}
 * @constructor
 */
const UserFriendsList = ({ ...props }) => {
    const friends = useUserFriends();

    return (
        <List
            {...props}
        >
            {friends.map(friend =>
                <UserListItem
                    key={friend.id}
                    userId={friend.id}
                />
            )}
        </List>
    )
}

export default UserFriendsList;