import ThreeDotsMenuButton from 'components/common/ThreeDotsMenuButton';
import TimerSessionMenu from 'components/timers/TimerSessionMenu';
import UserAvatar from "components/users/UserAvatar";
import dayjs from "dayjs";
import { useTimerSession } from 'hooks/timer-session-hooks';
import PropTypes from "prop-types";
import { formatDuration } from "utils/duration-formatter";
import OutlinedBox from "components/timers/outlined-box/OutlinedBox";
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";

const dateFormatTemplate = 'MMMM DD';

/**
 * Timer session card, contained in Box
 * @param {string} sessionId session ID
 * @returns {JSX.Element}
 * @constructor
 */
const TimerSessionItem = ({ sessionId }) => {
    const theme = useTheme();
    const session = useTimerSession(sessionId);

    return (
        <OutlinedBox
            label={dayjs(session.startTime).format(dateFormatTemplate)}
            color={session.endTime ? theme.palette.divider : 'text.info'}
        >
            <Box display='flex' width='100%' flexDirection='row' justifyContent='space-between' alignItems='center'
                 sx={{ height: '60px', p: 1 }}>
                <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: "80px" }}>
                    <Typography component={'span'} variant='body2' sx={{ p: 2, color: 'text.info' }}>
                        <UserAvatar
                            userId={session.ownerId}
                            sx={{ height: '25px', width: '25px' }}
                        />
                    </Typography>
                </Box>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                     sx={{ pl: 1, width: '100%' }}>
                    <Typography variant='h3' component='span'>
                        {formatDuration(session.duration)}
                    </Typography>
                </Box>
                <ThreeDotsMenuButton menuContents={<TimerSessionMenu session={session} />} />
            </Box>
        </OutlinedBox>
    )
}

TimerSessionItem.propTypes = {
    sessionId: PropTypes.string.isRequired,
    value: PropTypes.number,
}
export default TimerSessionItem;
