export const TaskStateType = {
    // OPEN: 'OPEN',
    // CLOSED: 'CLOSED',
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    CANCELLED: 'CANCELLED',
}

export const TaskStateGroups = {
    open: [TaskStateType.NOT_STARTED, TaskStateType.IN_PROGRESS],
    closed: [TaskStateType.COMPLETED, TaskStateType.CANCELLED],
}
